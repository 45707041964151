import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SuscripcionService {
  private plan$: BehaviorSubject<string> = new BehaviorSubject<string>('BASIC');

  get plan() {
    return this.plan$.asObservable();
  }

  set planSet(plan: string) {
    this.plan$.next(plan);
  }

  constructor(public http: HttpClient) {}

  urlSuscripcion(token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = ` query mbempresa{
  mbempresa{
    mbEmpresa{
      _id
      stripeSuscripcionAnualPro
      stripeSuscripcionAnualPremium
      stripeSuscripcionMensualPro
      stripeSuscripcionMensualPremium

    }
  }
}`;

    return this.http.post(environment.urlGraphql, { query }, { headers });
  }

  actualizarMbcliente(
    idCliente: string,
    planSeleccionado: boolean,
    token: string,
  ): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation updateMbcliente($updateMbclienteInput:UpdateMbclienteInput!){
      updateMbcliente(updateMbclienteInput:$updateMbclienteInput){
        message
        success
        mbCliente {
          planSeleccionado
        }
      }
    }`;

    const variables = {
      updateMbclienteInput: {
        id: idCliente,
        planSeleccionado: planSeleccionado,
      },
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  borrarSuscripcion(idCliente: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `mutation updateMbcliente($updateMbclienteInput:UpdateMbclienteInput!){
  updateMbcliente(updateMbclienteInput:$updateMbclienteInput){
    message
    success

  }
}`;

    const variables = {
      updateMbclienteInput: {
        id: idCliente,
        tipoSuscripcion: null,
        fechaHoraSuscripcion: null,
        mbTransaccionSuscripcion: null,
      },
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  obtenerSuscripcion(idCliente: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbcliente($id:ID!){
      mbcliente(id:$id) {
        mbCliente {
          tipoSuscripcion
          fechaHoraSuscripcion
          codigoReferido
          mbTransaccionSuscripcion {
            transaccion
          }
        }
      }
    }`;

    const variables = {
      id: idCliente,
    };
    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  cancelarSuscripcion(idCliente: string, token: string) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKeyPay);
    return this.http.post<any>(
      environment.urlPayments + '/stripe/cancelarSuscripcion',
      { id: idCliente },
      { headers },
    );
  }
}

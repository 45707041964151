import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@helpers/shared.helpers';
import { IChat } from '../models/chat.state';
import { Mbcliente } from '@models/auth.model';
import { MbHistorialChat } from '@models/mb-historial-chat.model';

const fileName = 'chat';

export const getChatDetailsLoad = createAction(
  buildStringNameForAction(fileName, 'Get chat details load'),
  props<{ profileId: string }>(),
);

export const getChatDetailsSuccess = createAction(
  buildStringNameForAction(fileName, 'Set chat details success'),
  props<{ chat: IChat }>(),
);

export const getChatMessagesSuccess = createAction(
  buildStringNameForAction(fileName, 'Set chat messages success'),
  props<{ messages: MbHistorialChat[] }>(),
);

export const getListOfChatLoad = createAction(
  buildStringNameForAction(fileName, 'Get list of chats load'),
);
export const getListOfChatSuccess = createAction(
  buildStringNameForAction(fileName, 'Get list of chats success'),
  props<{ listChats: Mbcliente[] }>(),
);
export const setMessage = createAction(
  buildStringNameForAction(fileName, 'Set message'),
  props<{ message: MbHistorialChat }>(),
);

export const sendMessageLoad = createAction(
  buildStringNameForAction(fileName, 'Send message load'),
  props<{ message: string }>(),
);

export const resetChatSelected = createAction(
  buildStringNameForAction(fileName, 'Reset chat selected'),
);

export const sendMessageSuccess = createAction(
  buildStringNameForAction(fileName, 'Send message success'),
);
export const resetSendMessageSuccess = createAction(
  buildStringNameForAction(fileName, 'Reset send message success'),
);
export const sendMessageFailed = createAction(
  buildStringNameForAction(fileName, 'Send message failed'),
);

import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { AppStoreKeys } from '@helpers/core.helpers';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { AuthState } from '../modules/auth/models/auth-state.model';
import { authReducer } from '../modules/auth/store/auth.reducer';
import { RouterUrlState } from '../modules/router/models/router-state.model';
import { settingsReducer } from '../modules/settings/store/settings.reducer';
import { SettingsState } from '../modules/settings/models/settings-state.model';
import { ClientState } from '../modules/client/models/client-state.model';
import { clientReducer } from '../modules/client/store/client.reducer';
import { ProfileState } from '../modules/profile/models/profile-state.model';
import { profileReducer } from '../modules/profile/store/profile.reducer';
import { ChatState } from '../modules/chat/models/chat.state';
import { chatReducer } from '../modules/chat/store/chat.reducer';
export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage];

export interface AppState {
  [AppStoreKeys.auth]: AuthState;
  [AppStoreKeys.settings]: SettingsState;
  [AppStoreKeys.client]: ClientState;
  [AppStoreKeys.profile]: ProfileState;
  [AppStoreKeys.chat]: ChatState;
}
export const reducers: ActionReducerMap<AppState> = {
  [AppStoreKeys.auth]: authReducer,
  [AppStoreKeys.settings]: settingsReducer,
  [AppStoreKeys.client]: clientReducer,
  [AppStoreKeys.profile]: profileReducer,
  [AppStoreKeys.chat]: chatReducer,
};
export const selectRouterState = createFeatureSelector<RouterReducerState<RouterUrlState>>(
  AppStoreKeys.router,
);

export const selectAuthState = createFeatureSelector<AuthState>(AppStoreKeys.auth);

export const selectSettingsState = createFeatureSelector<SettingsState>(AppStoreKeys.settings);
export const selectClientsState = createFeatureSelector<ClientState>(AppStoreKeys.client);
export const selectProfileState = createFeatureSelector<ProfileState>(AppStoreKeys.profile);
export const selectChatState = createFeatureSelector<ChatState>(AppStoreKeys.chat);

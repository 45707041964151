import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContactosService {
  constructor(private http: HttpClient) {}

  enviarSolicitud(idPerfil: string, idPerfilContacto: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation enviarSolicitud($id:ID! $idPerfilContacto:ID!){

  enviarSolicitud(id:$id idPerfilContacto:$idPerfilContacto){

    success
    message
  }
}`;

    const variables = {
      id: idPerfil,
      idPerfilContacto,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  solicitudContactos(id: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbperfilSolicitudContactos($id:ID!){
      mbperfilSolicitudContactos(id:$id){
        nombre
        imagen
        _id
        listaInfoIdioma{
          profesion
        }
      }
    }`;

    const variables = {
      id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers}).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => new Error(error));
      }),
    );
  }

  listaContactos(id: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbperfilContactos($id:ID!){
      mbperfilContactos(id:$id){
        nombre
        imagen
        ciudad
        _id
        listaInfoIdioma{
          profesion
        }

      }

    }`;

    const variables = {
      id,
    };
    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  aceptarSolicitud(idPerfil: string, idPerfilContacto: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation aceptarSolicitud($id:ID! $idPerfilContacto:ID!){
      aceptarSolicitud(id:$id idPerfilContacto:$idPerfilContacto){

        success
        message
      }
    }`;

    const variables = {
      id: idPerfil,
      idPerfilContacto,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  rechazarSolicitud(idPerfil: string, idPerfilContacto: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation rechazarSolicitud($id:ID! $idPerfilContacto:ID!){
      rechazarSolicitud(id:$id idPerfilContacto:$idPerfilContacto){

        success
        message
      }
    }`;

    const variables = {
      id: idPerfil,
      idPerfilContacto,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  eliminarContacto(idPerfil: string, idPerfilContacto: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation eliminarContacto($id:ID! $idPerfilContacto:ID!){
      eliminarContacto(id:$id idPerfilContacto:$idPerfilContacto){

        success
        message
      }
    }`;

    const variables = {
      id: idPerfil,
      idPerfilContacto,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  leerPerfil(id: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbperfil($id:ID!){
      mbperfil(id:$id){
        mbPerfil{
          nombre
          _id
          listaMbContacto
          listaMbContactoSolicitud
        }

      }
    }`;

    const variables = {
      id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
}

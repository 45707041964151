import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(private http: HttpClient) { }

  verificarUso(id: string): Observable<any> {
    var headers = new HttpHeaders().set("Authorization", "Bearer " + 'token').set("x-api-key", environment.apiKey);
    const query = `query mbtarjetaVerificarUso($tarjeta:String!){
  mbtarjetaVerificarUso(tarjeta: $tarjeta){
    success
    message
    mbPerfil{
       _id
  nombre
  pais
  ciudad
  correo
  telefono
  listaEnlace
  direccion
  tema
  imagen
  usuario
  whatsapp
  edad
  peso
  calificacion
  verCorreo
  verDireccion
  verAltura
  verCiudad
  verDescripcion
  verEdad
  verPais
  verPeso
  verTelefono
  verWhatsapp
  altura

  listaDocumento{
  descripcion
  listaArchivo
  titulo

    }
    listaInfoIdioma{
      idioma
      mbIndustria{
        _id
        catalogo
      }
      profesion
      descripcion
      listaEtiqueta
    }
listaExperienciaLaboral{
actividad
cargo
empresa
esActual
fechaDesde
fechaHasta

}
mbCliente{
  mbTipoCuenta{
    _id
    catalogo
  }
}
    }
  }
  
  
}`;

    const variables = {
      "tarjeta": id

    }


    return this.http.post(environment.urlGraphql, { query, variables }, { headers })
  }

  asociarTarjeta(idCliente: string, tarjetaId: string, token: string): Observable<any> {
    var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", environment.apiKey);

    const query = `mutation asociarTarjeta($idCliente:ID!, $tarjeta:String!){
  asociarTarjeta(idCliente:$idCliente, tarjeta:$tarjeta){
success
message
    
  }
}`;

    const variables = {
      "idCliente": idCliente,
      "tarjeta": tarjetaId
    }

    return this.http.post(environment.urlGraphql, { query, variables }, { headers })
  }
}

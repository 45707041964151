import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class LoadjsonService {
  ln: string;
  ln$: Observable<any>;
  langs: Array<string> = new Array();
  dinLang: any;
  prevLang: any;
  private changeLanguageSubject = new BehaviorSubject<string>('');
  //hacer variable de cada page para cargar de una el idioma principal. salvo los que
  //son dinamicos

  constructor(private http: HttpClient) {
    //console.log('Working service');
    if (window.navigator.language.startsWith('es')) {
      this.ln = 'es';
    } else if (window.navigator.language.startsWith('fr')) {
      this.ln = 'fr';
    } else if (window.navigator.language.startsWith('de')) {
      this.ln = 'de';
    } else if (window.navigator.language.startsWith('zh')) {
      this.ln = 'zh-Cn';
    } else if (window.navigator.language.startsWith('ar')) {
      this.ln = 'ar';
    } else if (window.navigator.language.startsWith('tl')) {
      this.ln = 'tl';
    } else if (window.navigator.language.startsWith('vi')) {
      this.ln = 'vi';
    } else if (window.navigator.language.startsWith('ko')) {
      this.ln = 'ko';
    } else if (window.navigator.language.startsWith('ru')) {
      this.ln = 'ru';
    } else if (window.navigator.language.startsWith('he')) {
      this.ln = 'he';
    } else if (window.navigator.language.startsWith('pt')) {
      this.ln = 'pt';
    } else if (window.navigator.language.startsWith('hi')) {
      this.ln = 'hi';
    } else {
      this.ln = 'en';
    }
    // this.ln = window.navigator.language;
    this.dinLang = this.ln;
    this.prevLang = this.ln;
    //console.log(this.ln);
   
    this.langs = ['en', 'es', 'fr', 'de', 'zh-CN', 'ar', 'tl', 'vi', 'ko', 'ru', 'he', 'pt', 'hi'];
  }

  get changeLanguageGet(): Observable<string> {
    return this.changeLanguageSubject.asObservable();
  }

  loadProfile(): Observable<any> {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/profile/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/profile/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/profile/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/profile/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/profile/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/profile/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/profile/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/profile/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/profile/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/profile/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/profile/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/profile/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/profile/hi.json');
    }
  }

  loadCalendar(): Observable<any> {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/calendar/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/calendar/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/calendar/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/calendar/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/calendar/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/calendar/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/calendar/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/calendar/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/calendar/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/calendar/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/calendar/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/calendar/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/calendar/hi.json');
    }
  }

  loadProfileLang(lang: string) {
    switch (lang) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/profile/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/profile/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/profile/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/profile/de.json');
    }
  }
  loadConfig(): Observable<any> {
    // if (this.ln === 'en') {

    //   return this.http.get<any>('../../assets/i18n/config/en.json');
    // }
    // else {

    //   return this.http.get<any>('../../assets/i18n/config/es.json');

    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/config/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/config/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/config/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/config/de.json');
      default:
    }
  }
  loadPlanes() {
    // if (this.ln === 'en') {

    //   return this.http.get('../../assets/i18n/planes/en.json');
    // }
    // else {

    //   return this.http.get('../../assets/i18n/planes/es.json');

    // }

    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/planes/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/planes/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/planes/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/planes/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/planes/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/planes/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/planes/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/planes/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/planes/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/planes/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/planes/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/planes/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/planes/hi.json');
    }
  }
  loadForm() {
    // if (this.dinLang === 'en') {

    //   return this.http.get<any>('../../assets/i18n/profile/en.json');

    // }
    // else {

    //   return this.http.get<any>('../../assets/i18n/profile/es.json');

    // }
    switch (this.dinLang) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/profile/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/profile/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/profile/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/profile/de.json');
    }
  }

  /* === SERVICES === */

  loadServicesAdd() {
    // if (this.dinLang === 'en') {
    //   return this.http.get<any>('../../assets/i18n/services/add/en.json');
    // }
    // else {
    //   return this.http.get<any>('../../assets/i18n/services/add/es.json');
    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/services/add/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/services/add/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/services/add/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/services/add/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/services/add/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/services/add/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/services/add/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/services/add/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/services/add/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/services/add/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/services/add/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/services/add/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/services/add/hi.json');
    }
  }

  loadServicesEdit() {
    // if (this.dinLang === 'en') {
    //   return this.http.get<any>('../../assets/i18n/services/edit/en.json');
    // }
    // else {
    //   return this.http.get<any>('../../assets/i18n/services/edit/es.json');
    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/services/edit/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/services/edit/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/services/edit/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/services/edit/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/services/edit/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/services/edit/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/services/edit/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/services/edit/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/services/edit/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/services/edit/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/services/edit/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/services/edit/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/services/edit/hi.json');
    }
  }

  loadServicesList() {
    // if (this.dinLang === 'en') {
    //   return this.http.get<any>('../../assets/i18n/services/list/en.json');
    // }
    // else {
    //   return this.http.get<any>('../../assets/i18n/services/list/es.json');
    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/services/list/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/services/list/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/services/list/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/services/list/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/services/list/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/services/list/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/services/list/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/services/list/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/services/list/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/services/list/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/services/list/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/services/list/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/services/list/hi.json');
    }
  }

  /* === PROFILE === */

  previewForm() {
    // if (this.prevLang === 'en') {

    //   return this.http.get<any>('../../assets/i18n/profile/en.json');

    // }
    // else {

    //   return this.http.get<any>('../../assets/i18n/profile/es.json');

    // }
    switch (this.prevLang) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/profile/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/profile/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/profile/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/profile/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/profile/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/profile/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/profile/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/profile/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/profile/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/profile/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/profile/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/profile/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/profile/hi.json');

    }
  }
  public loadLogin() {
    // if (this.ln === 'en') {

    //   return this.http.get('../../assets/i18n/signin/en.json');

    // }
    // else {
    //   this.ln$ = new Observable<any>(subscribe => {

    //     subscribe.next(this.http.get('../../assets/i18n/signin/es.json'))

    //   })
    //   return this.http.get('../../assets/i18n/signin/es.json');

    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/register/signin/en.json');
      case 'es':
        this.ln$ = new Observable<any>((subscribe) => {
          subscribe.next(this.http.get('../../assets/i18n/register/signin/es.json'));
        });
        return this.http.get<any>('../../assets/i18n/register/signin/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/register/signin/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/register/signin/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/register/signin/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/register/signin/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/register/signin/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/register/signin/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/register/signin/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/register/signin/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/register/signin/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/register/signin/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/register/signin/hi.json');
    }
  }

  loadBuy() {
    // if (this.ln === 'en') {

    //   return this.http.get('../../assets/i18n/buy/en.json');
    // }
    // else {

    //   return this.http.get('../../assets/i18n/buy/es.json');

    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/buy/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/buy/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/buy/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/buy/de.json');
    }
  }

  changeLanguage(lang: string) {
    this.dinLang = lang;
  }
  previewLang(lang: string) {
    this.prevLang = lang;
  }

  loadPayments() {
    // if (this.ln === 'en') {

    //   return this.http.get('../../assets/i18n/payments/en.json');
    // }
    // else {

    //   return this.http.get('../../assets/i18n/payments/es.json');

    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/payments/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/payments/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/payments/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/payments/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/payments/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/payments/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/payments/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/payments/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/payments/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/payments/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/payments/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/payments/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/payments/hi.json');
    }
  }

  loadSteps() {
    // if (this.ln === 'en') {

    //   return this.http.get('../../assets/i18n/steps/en.json');
    // }
    // else {

    //   return this.http.get('../../assets/i18n/steps/es.json');

    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/steps/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/steps/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/steps/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/steps/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/steps/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/steps/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/steps/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/steps/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/steps/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/steps/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/steps/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/steps/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/steps/hi.json');
    }
  }

  loadPolitics() {
    // if (this.ln === 'en') {

    //   return this.http.get('../../assets/i18n/politics/en.json');
    // }
    // else {

    //   return this.http.get('../../assets/i18n/politics/es.json');

    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/politics/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/politics/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/politics/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/politics/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/politics/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/politics/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/politics/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/politics/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/politics/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/politics/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/politics/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/politics/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/politics/hi.json');
    }
  }

  loadCitas() {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/cita/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/cita/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/cita/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/cita/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/cita/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/cita/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/cita/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/cita/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/cita/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/cita/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/cita/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/cita/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/cita/hi.json');
    }
  }

  loadMainSearch() {
    // if (this.ln === 'en') {

    //   return this.http.get('../../assets/i18n/main-search/en.json');
    // }
    // else {

    //   return this.http.get('../../assets/i18n/main-search/es.json');

    // }
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/main-search/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/main-search/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/main-search/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/main-search/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/main-search/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/main-search/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/main-search/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/main-search/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/main-search/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/main-search/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/main-search/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/main-search/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/main-search/hi.json');
    }
  }

  loadCuenta(): Observable<any> {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/cuenta/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/cuenta/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/cuenta/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/cuenta/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/cuenta/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/cuenta/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/cuenta/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/cuenta/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/cuenta/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/cuenta/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/cuenta/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/cuenta/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/cuenta/hi.json');
    }
  }
  loadReferral(): Observable<any> {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/cuenta/links/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/cuenta/links/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/cuenta/links/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/cuenta/links/de.json');
    }
  }

  loadReservas(): Observable<any> {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/reservas/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/reservas/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/reservas/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/reservas/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/reservas/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/reservas/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/reservas/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/reservas/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/reservas/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/reservas/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/reservas/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/reservas/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/reservas/hi.json');
    }
  }
  loadScan(): Observable<any> {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/scan/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/scan/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/scan/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/scan/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/scan/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/scan/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/scan/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/scan/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/scan/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/scan/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/scan/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/scan/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/scan/hi.json');
    }
  }

  loadHistory(): Observable<any> {
    switch (this.ln) {
      case 'en':
        return this.http.get<any>('../../assets/i18n/historial/en.json');
      case 'es':
        return this.http.get<any>('../../assets/i18n/historial/es.json');
      case 'fr':
        return this.http.get<any>('../../assets/i18n/historial/fr.json');
      case 'de':
        return this.http.get<any>('../../assets/i18n/historial/de.json');
      case 'zh-CN':
        return this.http.get<any>('../../assets/i18n/historial/zh-CN.json');
      case 'ar':
        return this.http.get<any>('../../assets/i18n/historial/ar.json');
      case 'tl':
        return this.http.get<any>('../../assets/i18n/historial/tl.json');
      case 'vi':
        return this.http.get<any>('../../assets/i18n/historial/vi.json');
      case 'ko':
        return this.http.get<any>('../../assets/i18n/historial/ko.json');
      case 'ru':
        return this.http.get<any>('../../assets/i18n/historial/ru.json');
      case 'he':
        return this.http.get<any>('../../assets/i18n/historial/he.json');
      case 'pt':
        return this.http.get<any>('../../assets/i18n/historial/pt.json');
      case 'hi':
        return this.http.get<any>('../../assets/i18n/historial/hi.json');

    }
  }

}

import { Injectable } from '@angular/core';
import { BaseService } from '@services/base.service';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { selectClientToken } from '@auth-module/store/auth.selectors';
import { catchError, Observable, throwError, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { isEmpty } from 'lodash-es';
import { ApiResponse } from '@models/api.models';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {
  token = '';
  constructor(private store: Store, http: HttpClient) {
    super(http);
    this.store.select(selectClientToken).subscribe((token: string) => {
      this.token = token;
    });
  }

  getChatList(idCliente: string, idPerfil: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbhistorialchatConversacionPorClientePerfil($idCliente: ID! $idPerfil: ID!) {
      mbhistorialchatConversacionPorClientePerfil(idCliente: $idCliente idPerfil: $idPerfil) {
        _id
        cantidadNoLeido
        mbUltimoChat {
          mensaje
          fechaHoraRegistro
          mbClienteRecibe {
            _id
            nombres
            apellidos
            imagen
            cantidadNoLeido
          }
          mbClienteEnvia {
            _id
            nombres
            apellidos
            imagen
            cantidadNoLeido
          }
          mbPerfilRecibe {
            _id
            nombre
            imagen
            listaInfoIdioma {
              profesion
            }
          }
          mbPerfilEnvia {
            _id
            nombre
            imagen
            listaInfoIdioma {
              profesion
            }
          }
        }
      }
    }`;
    const variables = {
      idCliente,
      idPerfil,
    };

    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map((resp: ApiResponse) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          const error = resp.errors[0].message;
          console.error(error);
          throwError(() => error);
          return null;
        }
        return resp.data;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      }),
    );
  }

  getProfileOfChat(id: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbperfil($id: ID!) {
      mbperfil(id: $id) {
        mbPerfil {
          _id
          imagen
          nombre
          listaInfoIdioma {
            profesion
          }
          mbCliente {
            _id
            nombres
            imagen
          }
        }
      }
    }`;
    const variables = {
      id,
    };

    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map((resp: ApiResponse) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          const error = resp.errors[0].message;
          console.error(error);
          throwError(() => error);
          return null;
        }
        return resp.data;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      }),
    );
  }

  getChatHistory(
    idCliente: string,
    idClienteContacto: string,
    idPerfil: string,
    idPerfilContacto: string,
  ): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbhistorialchatPorClientePerfil (
    $idCliente: ID!
    $idClienteContacto: ID!
    $idPerfil: ID
    $idPerfilContacto: ID) {
      mbhistorialchatPorClientePerfil (
        idCliente: $idCliente
        idClienteContacto: $idClienteContacto
        idPerfil: $idPerfil
        idPerfilContacto: $idPerfilContacto
      ) {
        _id
        fechaHoraRegistro
        mbClienteEnvia {
          _id
        }
        mbClienteRecibe {
          _id
        }
        mbPerfilEnvia {
          _id
        }
        mbPerfilRecibe {
          _id
        }
        mensaje
      }
    }`;
    const variables = {
      idCliente,
      idClienteContacto,
      idPerfil,
      idPerfilContacto,
    };

    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map((resp: ApiResponse) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          const error = resp.errors[0].message;
          console.error(error);
          throwError(() => error);
          return null;
        }
        return resp.data;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      }),
    );
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { setProfileIsLoading, setShowModalOfDeleteProfile, setShowModalOfProfiles, setShowModalOfServices } from './profile.actions';
import { ProfileState } from '../models/profile-state.model';

export const initialState: ProfileState = {
  showModalOfProfiles: false,
  showModalOfServices: false,
  showModalOfDeleteProfile: false,
  profileIsLoading: false,
};
const reducer = createReducer(
  { ...initialState },
  on(
    setShowModalOfProfiles,
    (state: ProfileState, { showModalOfProfiles }): ProfileState => ({
      ...state,
      showModalOfProfiles,
    }),
  ),
  on(
    setShowModalOfServices,
    (state: ProfileState, { showModalOfServices }): ProfileState => ({
      ...state,
      showModalOfServices,
    }),
  ),
  on(
    setShowModalOfDeleteProfile,
    (state: ProfileState, { showModalOfDeleteProfile }): ProfileState => ({
      ...state,
      showModalOfDeleteProfile,
    }),
  ),
  on(
    setProfileIsLoading,
    (state: ProfileState, { profileIsLoading }): ProfileState => ({
      ...state,
      profileIsLoading,
    }),
  ),
);

export function profileReducer(state: ProfileState | undefined, action: Action) {
  return reducer(state, action);
}

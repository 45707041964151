import {Link, LinkType} from '../core/models/shared';

import psl from 'psl';

export function parseLink(raw: string): Link {
  let link = raw.trim().replace('https://', '').replace('http://', '');
  const createdLink = {type: LinkType.OTHER, url: link} as Link;

  link = link.toLowerCase();
  if (link.includes(LinkType.FACEBOOK)) {
    createdLink.type = LinkType.FACEBOOK;
  } else if (link.includes(LinkType.INSTAGRAM)) {
    createdLink.type = LinkType.INSTAGRAM;
  } else if (link.includes(LinkType.LINKEDIN)) {
    createdLink.type = LinkType.LINKEDIN;
  } else if (link.includes(LinkType.TWITTER)) {
    createdLink.type = LinkType.TWITTER;
  } else if (link.includes(LinkType.TIDAL)) {
    createdLink.type = LinkType.TIDAL;
  } else if (link.includes(LinkType.TIKTOK)) {
    createdLink.type = LinkType.TIKTOK;
  } else if (link.includes(LinkType.YOUTUBE) || link.includes('youtu.be')) {
    createdLink.type = LinkType.YOUTUBE;
  } else if (link.includes(LinkType.SPOTIFY)) {
    createdLink.type = LinkType.SPOTIFY;
  } else if (link.includes(LinkType.WHATSAPP)) {
    createdLink.type = LinkType.WHATSAPP;
  } else if (link.includes(LinkType.IMDB)) {
    createdLink.type = LinkType.IMDB;
  } else if (link.includes(LinkType.PINTEREST)) {
    createdLink.type = LinkType.PINTEREST;
  } else if (link.includes(LinkType.DEEZER)) {
    createdLink.type = LinkType.DEEZER;
  } else if (link.includes(LinkType.APPLE_MUSIC)) {
    createdLink.type = LinkType.APPLE_MUSIC;
  } else if (link.includes(LinkType.AMAZON_MUSIC)) {
    createdLink.type = LinkType.AMAZON_MUSIC;
  }

  return createdLink;
}

export function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getDomain(url: string): string {
  return psl.parse(url).domain;
}

export function getLinkDisplayName(link: Link) {
  switch (link.type) {
    case LinkType.FACEBOOK:
      return 'Facebook';
    case LinkType.YOUTUBE:
      return 'YouTube';
    case LinkType.INSTAGRAM:
      return 'Instagram';
    case LinkType.TIKTOK:
      return 'TikTok';
    case LinkType.LINKEDIN:
      return 'LinkedIn';
    case LinkType.TWITTER:
      return 'Twitter';
    case LinkType.SPOTIFY:
      return 'Spotify';
    case LinkType.WHATSAPP:
      return 'WhatsApp';
    case LinkType.IMDB:
      return 'IMDb';
    case LinkType.PINTEREST:
      return 'Pinterest';
    case LinkType.EMAIL:
      return link.url.slice(7, 35);
    default:
      return getDomain(link.url);
  }
}

export function priceToDecimal(price: string): number {
  return Number(price) / 1000;
}
export function savingSubscription(monthPrice: number, yearPrice: number): number {
  const monthlyPriceYear = monthPrice * 12;
  const saving = monthlyPriceYear - yearPrice;
  return (saving / monthlyPriceYear) * 100;
}

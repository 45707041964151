import { createAction, props } from '@ngrx/store';
import { AuthState } from '../models/auth-state.model';
import { buildStringNameForAction } from '@helpers/shared.helpers';
import { Mbcliente } from '@models/auth.model';
import { CreateMbclienteempresaInput } from '@models/create-mb-cliente-empresa.model';

const fileName = 'auth';
export const updateToken = createAction(
  '[Auth] Update token',
  props<{ auth: AuthState; delay: boolean }>(),
);

export const set2faPassed = createAction(
  '[Auth] Set 2fa passed',
  props<{ is2faPassed: boolean }>(),
);

export const individualRegisterLoad = createAction(
  buildStringNameForAction(fileName, 'Individual register load'),
  props<{ nombre: string, email: string; password: string; referralCode: string; partner:string }>(),
);
export const individualRegisterSuccess = createAction(
  buildStringNameForAction(fileName, 'Individual register success'),
  props<{ client: Mbcliente; }>(),
);
export const individualRegisterError = createAction(
  buildStringNameForAction(fileName, 'Individual register error'),
);
export const businessRegisterLoad = createAction(
  buildStringNameForAction(fileName, 'Business register load'),
  props<{ createMbclienteempresaInput: CreateMbclienteempresaInput; }>(),
);
export const businessRegisterSuccess = createAction(
  buildStringNameForAction(fileName, 'Business register success'),
  props<{ client: Mbcliente; }>(),
);
export const businessRegisterError = createAction(
  buildStringNameForAction(fileName, 'Business register error'),
);
export const setClientSubscriptionSuccess = createAction(
  buildStringNameForAction(fileName, 'Set client subscription success'),
);
export const setClientSubscriptionError = createAction(
  buildStringNameForAction(fileName, 'Set client subscription error'),
);

export const validateCodeSuccess = createAction(
  buildStringNameForAction(fileName, 'Validate code success'),
);

export const authLogout = createAction('[Auth] Logout');

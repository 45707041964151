import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardService } from '../core/services/card.service';
import { Mbresult, UserData } from '../core/models/auth.model';
import { AlertsService } from '../core/services/alerts.service';
import { LoadjsonService } from '../core/services/loadjson.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '@core/store.state';
import { selectUserData } from '@auth-module/store/auth.selectors';
import { lastValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})
export class TagComponent implements OnInit {
  isLoading = true;
  scanError = false;
  otherError = false;
  profileNotActive = false;
  ts: any;
  userData: UserData;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cardServ: CardService,
    private alertServ: AlertsService,
    private translate: LoadjsonService,
    private store:Store<AppState>

  ) {
    this.translate.loadScan().subscribe((resp) => {
      this.ts = resp;
    });

 
  }

  async ngOnInit(): Promise<void> {
    const cardId = this.route.snapshot.queryParams.id;
    this.userData=await lastValueFrom(this.store.pipe(select(selectUserData), take(1)));

    if (cardId) {

      this.leerTarjeta(cardId);
    } else {

      this.router.navigate(['/profile']);
    }
  }
  leerTarjeta(id: string) {
    this.cardServ.verificarUso(id).subscribe((resp) => {
      const mbResult: Mbresult = resp.data.mbtarjetaVerificarUso;

      if(resp.errors) this.alertServ.sendMsj('danger', 'Connection problems');

      if (!mbResult.success) {
        if (mbResult.mbPerfil) {

          this.router.navigate(['profile/view', id], {
            queryParams: {ref: 'scan'},
            state: {profileData: mbResult.mbPerfil},
          });



        } else {

          this.scanError = true;
        }

      } else {
        //const userData = JSON.parse(localStorage.getItem('user-data'));

        if (this.userData.token) {
          this.cardServ.asociarTarjeta(this.userData.id, id, this.userData.token).subscribe((resp) => {
            const mbResult2: Mbresult = resp.data.asociarTarjeta;


            if (mbResult2.success) {
              this.alertServ.sendMsj('info', mbResult2.message);
              localStorage.setItem('card', JSON.stringify({id: id, state: 'inactive'}));
              this.router.navigate(['/profile']);
            } else {

              this.alertServ.sendMsj('danger', mbResult2.message);
              this.scanError = true;
              //console.log('mensaje ', mbResult2.message);
            }
          });
        } else {
          localStorage.setItem('card', JSON.stringify({id: id, state: 'inactive'}));
          this.router.navigate(['/register']);
        }
      }
    },(error)=>{
      this.isLoading = false;
      this.alertServ.sendMsj('danger', 'Connection problems');
    });

    this.isLoading = false;
  }



}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniSpinnerComponent } from './mini-spinner.component';



@NgModule({
  declarations: [MiniSpinnerComponent],
  exports: [MiniSpinnerComponent],
  imports: [
    CommonModule
  ]
})
export class MiniSpinnerModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Mbresult } from 'src/app/core/models/auth.model';
import { ReservaResponse } from 'src/app/core/models/reserva.model';
import { ReservaService } from 'src/app/core/services/reserva.service';
import { LoadjsonService } from 'src/app/core/services/loadjson.service';
import { Alert } from '../../core/models/alert.model';
import { AlertsService } from '../../core/services/alerts.service';
import { ServicioModel } from 'src/app/core/models/servicio.model';
import { SelectedCita } from 'src/app/core/models/calendar.model';

@Component({
  selector: 'app-reserve-time',
  templateUrl: './reserve-time.component.html',
  styleUrls: ['./reserve-time.component.css'],
})
export class ReserveTimeComponent implements OnInit {
  reserva: ReservaResponse;
  reservaId: string;
  token: string;
  servicio: ServicioModel;
  ts: any;
  msj: Alert;
  showSuccess: boolean;
  paraCliente: boolean;
  constructor(
    private reServ: ReservaService,
    private route: ActivatedRoute,
    private translate: LoadjsonService,
    private alertServ: AlertsService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.reservaId = params.id;
      this.token = params.token;
      this.paraCliente = params.paraCliente === 'true';
    });

    //language
    this.translate.loadCitas().subscribe((resp) => {
      this.ts = resp;
    });

    //alert services
    this.alertServ.messaje.subscribe((resp) => {
      this.msj = resp;
    });
  }

  ngOnInit(): void {
    this.leerReserva(this.reservaId, this.token);
  }

  leerReserva(reservaId: string, token: string) {
    this.reServ.leerReserva(reservaId, token).subscribe((resp) => {
      const mbResult: Mbresult = resp.data?.mbreserva;

      if (mbResult?.success) {
        this.reserva = resp.data.mbreserva.mbReserva;
        this.servicio = this.reserva.mbServicio;
        // console.log('servicio ' ,this.servicio);
      } else {
        //mensaje de error
        this.alertServ.sendMsj('danger', this.ts.reserveTime.alerts.readReserveError);
      }
    });
  }

  guardarNuevaHora(hora: number, min: number, mes: number, dia: number, anio: number) {
    //console.log(hora + ':' + min);

    let horaAnterior = new Date(anio, mes, dia, hora, min, 0, 0);
    //console.log('horaAnt', horaAnterior);
    //horaAnterior.setMonth(mes);
    //horaAnterior.setDate(dia);
    //horaAnterior.setFullYear(anio);
    //horaAnterior.setHours(hora);
    //horaAnterior.setMinutes(min);

    //const userData = JSON.parse(localStorage.getItem('user-data'));
    if (this.paraCliente) {
      this.reServ.cambiarFechaHora(horaAnterior, this.reserva._id, this.token).subscribe((resp) => {
        const mbResult: Mbresult = resp.data.cambiarFechaHoraMbreserva;

        if (mbResult.success) {
          //mensaje de success
          //console.log('success');
          this.showSuccess = true;
          // this.alertServ.sendMsj('success', this.ts.reserveTime.alerts.changeHourTimeSuccess);
        } else {
          //mensaje de error
          this.alertServ.sendMsj('danger', this.ts.reserveTime.alerts.changeHourTimeError);
        }
      });
    } else {
      this.reServ
        .cambiarFechaHoraReserva(this.reserva._id, horaAnterior, this.token)
        .subscribe((resp) => {
          const mbResult: Mbresult = resp.data.cambiarFechaHoraPorClienteMbreserva;

          if (mbResult.success) {
            //mensaje de success
            //console.log('success');
            this.showSuccess = true;
            // this.alertServ.sendMsj('success', this.ts.reserveTime.alerts.changeHourTimeSuccess);
          } else {
            //mensaje de error
            this.alertServ.sendMsj('danger', this.ts.reserveTime.alerts.changeHourTimeError);
          }
        });
    }
  }
  leerMes(): number {
    let fecha = new Date(this.reserva.fechaHoraReserva);
    return fecha.getMonth() + 1;
  }

  leerDia(): number {
    let fecha = new Date(this.reserva.fechaHoraReserva);
    return fecha.getDate();
  }

  leerAnio(): number {
    let fecha = new Date(this.reserva.fechaHoraReserva);

    return fecha.getFullYear();
  }
  timeSelected(event: SelectedCita) {
    console.log('event', event);
    this.guardarNuevaHora(
      event.hour,
      event.min,
      event.day.getMonth(),
      event.day.getDate(),
      event.day.getFullYear(),
    );
  }
}

export class BusyTime {
  fecha: Date;
  hour: number;
  min: number;
}

export class TimeOfCalendar {

  hour: number;
  min: number;
  busy?: boolean;
}

export class SelectedCita {

  day: Date;
  hour: number;
  min: number;
}
import { Injectable } from '@angular/core';
import { browserRegexFinder, Browsers } from '@helpers/shared.helpers';
import { Store } from '@ngrx/store';
import { AppState } from '../store.state';
import {
  SET_APP_VERSION,
  SET_APPLICATION_CONFIGURATION,
  SET_BROWSER,
  SET_PUBLIC_IP,
  setNavigatorLanguage,
} from '@settings-module/store/settings.actions';
import * as packageJson from 'package.json';
import { catchError, exhaustMap, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsState } from '@settings-module/models/settings-state.model';
import { map } from 'rxjs/operators';
import { IpService } from './ip.service';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  constructor(private store: Store<AppState>, private httpClient: HttpClient, private ipService:IpService) {}
  setCurrentBrowser(): void {
    const browserMatches: RegExpExecArray = browserRegexFinder.exec(navigator?.userAgent);
    const currentBrowser: Browsers = browserMatches ? Browsers[browserMatches[0]] : Browsers.Other;
    this.store.dispatch(SET_BROWSER({ currentBrowser }));
  }

  setCurrentBrowserLanguage(): void {
    let language = 'en';
    if (window.navigator.language.startsWith('es')) {
      language = 'es';
    } else if (window.navigator.language.startsWith('fr')) {
      language = 'fr';
    } else if (window.navigator.language.startsWith('de')) {
      language = 'de';
    } else {
      language = 'en';
    }
    this.store.dispatch(setNavigatorLanguage({ language }));
  }

  setVersionApp(): void {
    const packageVersion = JSON.stringify(packageJson);
    this.store.dispatch(SET_APP_VERSION({ appVersion: JSON.parse(packageVersion)?.version }));
  }

  setFunctionConfigObserver() {
    return of('').pipe(
      exhaustMap(() => {
        return this.httpClient.get<SettingsState>('assets/app-settings.json').pipe(
          map((appSettings: SettingsState) => {
            this.store.dispatch(
              SET_APPLICATION_CONFIGURATION({
                appSettings,
              }),
            );
            return true;
          }),
          catchError((error) => {
            return of(false);
          }),
        );
      }),
    );
  }

   setPublicIp(){
    let publicIp;
    this.ipService.getPublicIp().subscribe((response: any) => {
      publicIp = response.ip;
      
    });

    return of('').pipe(
      exhaustMap(() => {
        return  this.ipService.getPublicIp().pipe(
          map((response: any) => {
            this.store.dispatch(
              SET_PUBLIC_IP({
                publicIp: response.ip.toString(),
              }),
            );
            return true;
          }),
          catchError((error) => {
            return of(false);
          }),
        );
      }),
    );


  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import {AngularFirestore} from '@angular/fire/compat/firestore';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Appointment } from '../models/appointments';
import { ConnectionSnippet } from '../models/connection';
import { ProfileSnippet, User } from '../models/user';
//import firebase from 'firebase/compat/app';
import { ReservaService } from './reserva.service';
import { ContactosService } from './contactos.service';
import { Perfil } from '../models/perfil.model';
import { ReservaResponse } from '../models/reserva.model';
import { UserData } from '@models/auth.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store.state';
import { selectUserData } from '@auth-module/store/auth.selectors';
import { selectActiveProfileId } from '@client-module/store/client.selectors';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  userId;
  userData: UserData;
  activeProfileId:string;

  /**
   * -contador de notificaciones
   *  countNotifySubject
   * (implementacion a futuro)
   */
  private countNotifySubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private connectionRequests: BehaviorSubject<ConnectionSnippet[]> = new BehaviorSubject<
    ConnectionSnippet[]
  >([]);
  private upcomingAppts: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);
  connection = this.connectionRequests.asObservable();
  apointment = this.upcomingAppts.asObservable();
  profile: ProfileSnippet[];
  user: User;
  url: string = 'https://fcm.googleapis.com/fcm/send';

  constructor(
    //private firestore: AngularFirestore,
    public http: HttpClient,
    private reServ: ReservaService,
    private conServ: ContactosService,
    private store:Store<AppState>
  ) {
    this.store.select(selectUserData).subscribe(resp=>{
      //console.log('state user data ', resp);
      this.userData=resp;
    })
  }

  async updateNotifications(): Promise<void> {
    //const userData = JSON.parse(localStorage.getItem('user-data'));
    //const activeProfileId = localStorage.getItem('profile');
    this.activeProfileId = await lastValueFrom(
      this.store.pipe(select(selectActiveProfileId), take(1)),
    );
    if (this.userData) {
      // DOCS: Se cambia la validación ya que si no existe se asigna un string "undefined"
      if (this.activeProfileId !== 'undefined') {
        const request: Array<Observable<any>> = [
          this.conServ.solicitudContactos(this.activeProfileId, this.userData.token),
          this.reServ.leerReservasSolicidasProximas(this.userData.id, this.userData.token),
        ];
        const requestObservable = forkJoin(request);
        const subscription = requestObservable.subscribe({
          next: (resp) => {
            const perfiles: Perfil[] = resp[0].data?.mbperfilSolicitudContactos;
            const reservas: ReservaResponse[] = resp[1].data?.mbreservaPorClienteSolicitadaProxima;
            this.countNotifySubject.next((perfiles?.length || 0) + (reservas?.length || 0));
            subscription.unsubscribe();
          },
          error: (error) => {
            console.log('error', error);
          },
        });
      }
    }
  }

  /**
   * send a Push Notifications
   *
   *token, title, body, image,link
   */

  sendData(
    userToken: string,
    title: string,
    body: string,
    image: string,
    link: string,
  ): Observable<any> {
    var Key = environment.key;
    var headers = new HttpHeaders().set('Authorization', Key);
    //let message: pushMessage = new pushMessage();
    // message.registration_ids = [userId];
    // message.collapse_key = 'type_a'
    let notify;
    if (!image) {
      notify = {
        body: body,
        title: title,
        sound: 'default',
        icon: 'https://firebasestorage.googleapis.com/v0/b/contactame-app.appspot.com/o/logo-januus-white.png?alt=media&token=53e0abc5-c59f-4ed1-b039-2c1742f9a76f',
        click_action: link,
      };
    } else {
      notify = {
        body: body,
        title: title,
        sound: 'default',
        icon: 'https://firebasestorage.googleapis.com/v0/b/contactame-app.appspot.com/o/logo-januus-white.png?alt=media&token=53e0abc5-c59f-4ed1-b039-2c1742f9a76f',
        image: image,
        click_action: link,
      };
    }

    const message = {
      registration_ids: [userToken],
      collapse_key: 'type_a',
      notification: notify,
    };

    //message.notification = notify;
    //console.log(JSON.stringify(message));
    return this.http.post(this.url, message, {headers});
  }

  /**
   * Get de connection request
   *
   *
   */
  // loadConnectionRequestsInit(userId: string, activeProfileId: string): Subscription {
  //   return this.firestore
  //     .collection('notifications', (ref) =>
  //       ref
  //         .where('target', '==', userId)
  //         .where('targetProfile', '==', activeProfileId)
  //         .where('type', '==', NotificationType.CONNECTION_REQUEST)
  //         .where('actedUpon', '==', false),
  //     )
  //     .snapshotChanges()
  //     .subscribe((resp: any) => {
  //       this.countNotifySubject.next(resp.length);
  //       // if(resp.length < 1 )
  //       //         this.countNotifySubject.next(resp.length);
  //       //         else
  //       //   this.countNotifySubject.next(this.countNotifySubject.value + resp.length);

  //       const requests = resp.map((c) => {
  //         const req = c.payload.doc.data();
  //         return {
  //           ...req.data,
  //           id: c.payload.doc.id,
  //           createdAt: req.createdAt,
  //         };
  //       });
  //       this.connectionRequests.next(requests);
  //     });
  // }

  /**
   * Get de Appintments request
   *
   *
   */
  // loadAppointmentsInit(activeProfileId: string): Subscription {
  //   return this.firestore
  //     .collection('appointments', (ref) =>
  //       ref
  //         .where('profileId', '==', activeProfileId)
  //         .where('date', '>=', Date.now())
  //         .where('status', 'in', ['Confirmed', 'Pending'])
  //         .where('isView', '==', false)
  //         .orderBy('date', 'asc'),
  //     )
  //     .snapshotChanges()
  //     .subscribe((resp: any) => {
  //       // ver logica para que no duplique
  //       if (resp.length > 0)
  //         this.countNotifySubject.next(this.countNotifySubject.value + resp.length);

  //       const upcoming = resp.map((appt) => ({
  //         ...appt.payload.doc.data(),
  //         id: appt.payload.doc.id,
  //       }));
  //       this.upcomingAppts.next(upcoming);
  //     });
  // }
  // loadConnectionRequests(userId: string, activeProfileId: string): Observable<any> {
  //   return this.firestore
  //     .collection('notifications', (ref) =>
  //       ref
  //         .where('target', '==', userId)
  //         .where('targetProfile', '==', activeProfileId)
  //         .where('type', '==', NotificationType.CONNECTION_REQUEST)
  //         .where('actedUpon', '==', false),
  //     )
  //     .snapshotChanges();
  // }

  /**
   * Get de Appintments request
   *
   *
   */
  // loadAppointments(activeProfileId: string): Observable<any> {
  //   return this.firestore
  //     .collection('appointments', (ref) =>
  //       ref
  //         .where('profileId', '==', activeProfileId)
  //         .where('date', '>=', Date.now())
  //         .where('status', 'in', ['Confirmed', 'Pending'])
  //         .orderBy('date', 'asc'),
  //     )
  //     .snapshotChanges();
  // }
  // loadAppointmentsNotify(activeProfileId: string): Observable<any> {
  //   return this.firestore
  //     .collection('appointments', (ref) =>
  //       ref
  //         .where('profileId', '==', activeProfileId)
  //         .where('date', '>=', Date.now())
  //         .where('status', 'in', ['Confirmed', 'Pending'])
  //         .orderBy('date', 'asc')
  //         .orderBy('createdAt', 'desc'),
  //     )
  //     .snapshotChanges();
  // }
  // loadMyAppointments(activeProfileId: string): Observable<any> {
  //   return this.firestore
  //     .collection('appointments', (ref) =>
  //       ref
  //         .where('clientId', '==', activeProfileId)
  //         .where('date', '>=', Date.now())
  //         .where('status', 'in', ['Confirmed', 'Pending'])
  //         .orderBy('date', 'asc'),
  //     )
  //     .snapshotChanges();
  // }
  /**
   * Add token
   *
   */
  // addToken(id: string, valueToken: string) {
  //   this.firestore.doc(`users/${id}`).update({
  //     token: valueToken,
  //   });
  // }
  /**
   * Remove token
   *
   */
  // removeToken(id: string) {
  //   this.firestore.doc(`users/${id}`).update({
  //     token: '',
  //   });
  // }

  /**
   * Get token
   * in the future put on other service
   */
  // getUserInfo(
  //   id: string,
  // ): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
  //   //console.log('get token for ' + id);

  //   var getOptions: firebase.firestore.GetOptions = {
  //     source: 'default',
  //   };

  //   return this.firestore.doc(`users/${id}`).get(getOptions).toPromise();
  // }

  get countNotify(): Observable<number> {
    return this.countNotifySubject.asObservable();
  }
}

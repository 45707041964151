import { Action, createReducer, on } from '@ngrx/store';
import { ChatState } from '../models/chat.state';
import {
  getChatDetailsSuccess,
  getChatMessagesSuccess,
  getListOfChatSuccess,
  resetChatSelected,
  resetSendMessageSuccess,
  sendMessageFailed,
  sendMessageSuccess,
  setMessage,
} from '@chat-module/store/chat.actions';
import { filter } from 'lodash-es';
import { Mbcliente } from '@models/auth.model';
import { setSuccessUnreadChats } from '@client-module/store/client.actions';
export const initialState: ChatState = {
  listChats: [],
  selectedChat: null,
  isMessageSentSuccessfully: null,
  unreadChats: null,
};
const reducer = createReducer(
  { ...initialState },
  on(getListOfChatSuccess, (state: ChatState, { listChats }): ChatState => {
    const unreadChats = filter(listChats, (item: Mbcliente) => item.cantidadNoLeido > 0);
    return {
      ...state,
      listChats,
      unreadChats: unreadChats.length,
    };
  }),
  on(
    setSuccessUnreadChats,
    (state: ChatState, { unreadChats }): ChatState => ({
      ...state,
      unreadChats,
    }),
  ),
  on(
    getChatDetailsSuccess,
    (state: ChatState, { chat }): ChatState => ({
      ...state,
      selectedChat: chat,
    }),
  ),
  on(
    getChatMessagesSuccess,
    (state: ChatState, { messages }): ChatState => ({
      ...state,
      selectedChat: {
        ...state.selectedChat,
        messages,
      },
    }),
  ),
  on(
    sendMessageSuccess,
    (state: ChatState): ChatState => ({
      ...state,
      isMessageSentSuccessfully: true,
    }),
  ),
  on(
    resetSendMessageSuccess,
    (state: ChatState): ChatState => ({
      ...state,
      isMessageSentSuccessfully: null,
    }),
  ),
  on(
    sendMessageFailed,
    (state: ChatState): ChatState => ({
      ...state,
      isMessageSentSuccessfully: false,
    }),
  ),
  on(
    setMessage,
    (state: ChatState, { message }): ChatState => ({
      ...state,
      selectedChat: {
        ...state.selectedChat,
        messages: [...state.selectedChat.messages, message],
      },
    }),
  ),
  on(
    resetChatSelected,
    (state: ChatState): ChatState => ({
      ...state,
      selectedChat: null,
    }),
  ),
);

export function chatReducer(state: ChatState | undefined, action: Action) {
  return reducer(state, action);
}

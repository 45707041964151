import { selectProfileState } from '../../../core/store.state';
import { createSelector } from '@ngrx/store';
import { ProfileState } from '../models/profile-state.model';
import {
  selectClientSubscription,
  selectProfileListLength,
} from '@client-module/store/client.selectors';

export const selectProfilesState = createSelector(
  selectProfileState,
  (state: ProfileState) => state,
);
export const selectShowModalOfProfiles = createSelector(
  selectProfilesState,
  (state: ProfileState) => state?.showModalOfProfiles,
);
export const selectShowModalOfServices = createSelector(
  selectProfilesState,
  (state: ProfileState) => state?.showModalOfServices,
);
export const selectShowModalOfDeleteProfile = createSelector(
  selectProfilesState,
  (state: ProfileState) => state?.showModalOfDeleteProfile,
);
export const selectProfileIsLoading = createSelector(
  selectProfilesState,
  (state: ProfileState) => state?.profileIsLoading,
);
export const selectAbleToCreateProfile = createSelector(
  selectClientSubscription,
  selectProfileListLength,
  (clientSubscription: string, profileListLength: number) =>
    !!(
      (clientSubscription?.includes('premium') && profileListLength < 4) ||
      (clientSubscription?.includes('pro') && profileListLength < 2) ||
      (!clientSubscription && profileListLength < 1)
    ),
);

<div *ngIf="showSpinner" class="spinner">
  <app-spinner></app-spinner>
</div>

<!-- <div *ngIf="showSuccess">
<p>La reserva se cancelo con exito</p>
</div> -->



<div class="contenedor">

  <!-- === RESERVA CANCELADA === -->

<div class="reservaCancel" *ngIf="showSuccess">
 <img class="reservaCancel-Icon" src="../../../assets/service/reserve-cancel.png">
 <p class="cancel"> {{ts?.reserveCancel.cancel}} </p>
 <p class="message-cancel"> {{ts?.reserveCancel.messageCancel}} </p>
</div>

<section class="card-appointment" *ngIf="showReserve">

  <h2 class="title">{{ts.reservedCita.title}}</h2>

  <div class="info-appointment">

    <p class="item-appointment">
      <strong>{{ts.reservedCita.service}}</strong> {{ reserva.mbServicio.servicio }}
    </p>

    <p class="item-appointment">
      <strong>{{ts.reservedCita.date}}</strong> {{ reserva.fechaHoraReserva | date}}
    </p>

    <p class="item-appointment" *ngIf="reserva.notas">
      <strong>{{ts.reservedCita.note}}</strong> {{ reserva.notas }}
    </p>


    <p class="item-appointment" *ngIf="reserva.mbPerfilProfesional.nombre">
      <strong>{{ts.reservedCita.name}}</strong> {{ reserva.mbPerfilProfesional.nombre }}
    </p>

    <p class="item-appointment" *ngIf="reserva.mbPerfilProfesional.telefono">
      <strong>{{ts.reservedCita.phone}}</strong>
      <a href="tel:{{ reserva.mbPerfilProfesional.telefono }}">
        {{ reserva.mbPerfilProfesional.telefono }}
       </a>
    </p>

    <p class="item-appointment" *ngIf="reserva.mbPerfilProfesional.correo">
      <strong>{{ts.reservedCita.email}}</strong>
      <a href="mailto:{{ reserva.mbPerfilProfesional.correo }}">
        {{' '+ reserva.mbPerfilProfesional.correo }}
      </a>
    </p>

    <!-- === Client Details === -->

    <h2 class="sub-title">{{ts.reservedCita.title2}}</h2>

    <p class="item-appointment">
      <strong>{{ts.reservedCita.name}}</strong> {{ reserva.nombre }}
    </p>

    <p class="item-appointment">
      <strong>{{ts.reservedCita.email}}</strong>
      <a href="{{ 'mailto:' + reserva.correo }}">
        {{' '+ reserva.correo }}
      </a>
    </p>


    <p class="item-appointment" *ngIf="reserva.telefono">
      <strong>{{ts.reservedCita.phone}}</strong>
      <a href="{{ 'tel:' + reserva.telefono }}">
        {{ reserva.telefono }}
      </a>
    </p>

    <p class="item-appointment" *ngIf="reserva.mbPerfilProfesional.direccion">
      <strong>{{ts.reservedCita.address}}</strong> {{ reserva.mbPerfilProfesional.direccion + '('+ reserva.mbPerfilProfesional.ciudad }}
    </p>


    <p class="item-appointment" *ngIf="reserva.notas">
      <strong>{{ts.reservedCita.note}}</strong> {{ reserva.notas }}
    </p>

  </div>

  <div class="aditional-info-appointment">
    <!-- <p *ngIf="reserva.mbPerfilProfesional._id == activeProfileId">
      {{ts.reservedCita.info1}} <strong>{{reserva.nombre}}</strong> {{ts.reservedCita.info2}}
    </p>

    <p *ngIf="reserva.mbPerfilProfesional._id != activeProfileId">
      {{ts.reservedCita.info1}} <strong>{{reserva.mbPerfilProfesional.nombre}}</strong> {{ts.reservedCita.info2}}
    </p> -->
    <p >
      {{ts.reservedCita.info1}}  <strong [routerLink]="['/bookings']">{{ts.reservedCita.bookings}} </strong>
    </p>
  </div>

</section>


</div>

<div class="dark" *ngIf="showCancelMessage" (click)="showCancelMessage=!showCancelMessage"></div>
<div class="cancelarSuscripcion" *ngIf="showCancelMessage">
  <p >{{ts?.cancelMessage.question}}</p>
  <p >{{ts?.cancelMessage.warning}}</p>
  <div class="line">
    <button class="btnGrey" (click)="confirm()">{{ts?.cancelMessage.yes}}</button>
    <button class="btn" (click)="cancel()">{{ts?.cancelMessage.cancel}}</button>
  </div>
</div>




<app-toast *ngIf="msj" [msj]="msj"></app-toast>

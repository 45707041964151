import { createSelector } from '@ngrx/store';
import { selectAuthState, selectSettingsState } from '@core/store.state';
import { AuthState } from '../models/auth-state.model';
import { selectActiveProfile, selectActiveProfileId, selectClient } from '@client-module/store/client.selectors';
import { Mbcliente } from '@models/auth.model';
import { Perfil } from '@core/models/perfil.model';
import { SettingsState } from '@settings-module/models/settings-state.model';

export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state);

export const selectClientToken = createSelector(selectAuth, (state: AuthState) => state?.token);
export const selectIsLogged = createSelector(selectAuth, (state: AuthState) => state?.isLogged);
export const selectAccountIsActive = createSelector(selectAuth, (state: AuthState) => state?.accountIsActive);
export const selectSettings = createSelector(selectSettingsState, (state: SettingsState) => state);
export const selectPublicIp = createSelector(selectSettingsState, (settingsState:SettingsState) => settingsState.publicIp
);



export const selectUserData = createSelector(
  selectClient,
  selectAuth,
  (client: Mbcliente, authState: AuthState) => {
    return {
      token: authState.token,
      id: client?._id,
    };
  },
);
export const selectUserDataWithActiveProfileId = createSelector(
  selectClient,
  selectAuth,
  selectActiveProfileId,
  (client: Mbcliente, authState: AuthState, activeProfileId:string) => {
    return {
      token: authState.token,
      id: client?._id,
      activeProfileId
    };
  },
);
export const selectUserDataWithActiveProfileIdPublicIp = createSelector(
  selectClient,
  selectAuth,
  selectActiveProfileId,
  selectSettingsState,
  (client: Mbcliente, authState: AuthState, activeProfileId:string, settingsState:SettingsState) => {
    return {
      token: authState.token,
      id: client?._id,
      activeProfileId,
      publicIp: settingsState.publicIp
    };
  },
);

export const selectUserDataWithActiveProfile = createSelector(
  selectClient,
  selectAuth,
  selectActiveProfile,
  (client: Mbcliente, authState: AuthState, activeProfile:Perfil) => {
    return {
      token: authState.token,
      id: client?._id,
      activeProfile
    };
  },
);
export const selectUserDataEmail = createSelector(
  selectClient,
  selectAuth,
  (client: Mbcliente, authState: AuthState) => {
    return {
      token: authState.token,
      id: client?._id,
      email: client?.correo
    };
  },
);

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalendarCliComponent} from './calendar-cli.component';
import {SpinnerModule} from '../../../shared/spinner/spinner.module';
import { MiniSpinnerModule } from '../../../shared/mini-spinner/mini-spinner.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CalendarCliComponent],
  exports: [CalendarCliComponent],
  imports: [CommonModule, SpinnerModule, SpinnerModule, MiniSpinnerModule, ReactiveFormsModule]
})
export class CalendarCliModule {}

<!-- ==== CALENDARIO === -->
<div *ngIf="currentStep === steps.Date">
  <div class="content">
    <div class="wrapper">
      <div class="calendar">
        <div class="month">
          <div class="prev" (click)="moveDate('prev')">
            <span>&#10094;</span>
          </div>
          <div>
            <h2 id="month">{{ calendarCurrentMonth }} {{ calendarCurrentYear }}</h2>
            <p id="date_str"></p>
          </div>
          <div class="next" (click)="moveDate('next')">
            <span>&#10095;</span>
          </div>
        </div>
        <div class="weekdays">
          <div *ngFor="let day of ts?.dias; index as i"> {{ day }} </div>
        </div>
        <div *ngIf="loadDays" class="spinner">
          <app-spinner></app-spinner>
        </div>
        <div class="days" *ngIf="!loadDays">
          <div class="prev_date day" *ngFor="let item of lastMonthCalendarDays">
            <div [style.color]="item?.color" [style.font-weight]="item?.fontWeight" [style.animation]="item?.animation">{{
              item?.day
            }}</div>
          </div>
          <div class="day" *ngFor="let item of currentMonthCalendarDays">
            <div
              [ngClass]="
                item?.day === todayNumberDay?.day &&
                calendarCurrentMonth === todayDateMonth &&
                calendarCurrentYear === todayDateYear
                  ? 'today'
                  : 'prev_date day'
              "
              [style.color]="item?.color"
              [style.font-weight]="item?.fontWeight"
              [style.animation]="item?.animation"
              (click)="selectDay(item)"
              >{{ item?.day }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ballState">
      <div class="state">
        <div class="free balls"></div>
        {{ ts?.diasDisponibles }}
      </div>
    </div>
  </div>
</div>
<!-- ==== SELECCIONAR HORA === -->
<div class="time" *ngIf="currentStep === steps.Time">
  <div class> </div>
  <div class="flecha-back">
    <img (click)="back()" src="assets/buy/arrow-back.png" />
  </div>
  <h2 class="date-hour">{{ newDate | date : 'shortDate' }}</h2>
  <div class="content-hours">
    <ng-container *ngIf="!loadTime">
      <div *ngIf="timeOfCalendar.length < 1" class="noHorarios">{{ ts?.noHorarios }}</div>
      <div *ngIf="timeOfCalendar.length > 0">
        <select type="text" class="selectHora" [formControl]="horaSeleccionada" (change)="capturarHora()">
          <option value disabled hidden>{{ ts?.hora }}</option>
          <option *ngFor="let item of timeOfCalendar; index as i" [value]="item.hour + ':' + item.min">
            <div *ngIf="item.min == 0">{{ item.hour + ':00' }}</div>
            <div *ngIf="item.min != 0">{{ item.hour + ':' + item.min }}</div>
          </option>
        </select>
      </div>
    </ng-container>
    <ng-container *ngIf="loadTime">
      <app-mini-spinner></app-mini-spinner>
    </ng-container>
  </div>
</div>

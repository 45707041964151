import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Mbresult } from 'src/app/core/models/auth.model';
import { ReservaService } from 'src/app/core/services/reserva.service';
import { LoadjsonService } from 'src/app/core/services/loadjson.service';
import { Alert } from '../../core/models/alert.model';
import { AlertsService } from '../../core/services/alerts.service';

@Component({
  selector: 'app-reserve-ok',
  templateUrl: './reserve-ok.component.html',
  styleUrls: ['./reserve-ok.component.css'],
})
export class ReserveOkComponent implements OnInit {
  reservaId: string;
  token: string;
  showSuccess: boolean;
  paraCliente:boolean;
  ts: any;
  msj: Alert;

  constructor(
    private route: ActivatedRoute,
    private reServ: ReservaService,
    private translate: LoadjsonService,
    private alertServ: AlertsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.reservaId = params.id;
      this.token = params.token;
      this.paraCliente= params.paraCliente
    });

    //language
    this.translate.loadCitas().subscribe((resp) => {
      this.ts = resp;
    });

    //alert services
     this.alertServ.messaje.subscribe((resp) => {
      this.msj = resp;
    });
  }

  ngOnInit(): void {
    this.aceptarReserva(this.reservaId, this.token);
  }
  aceptarReserva(idReserva: string, token: string) {
    this.reServ.aceptarReserva(idReserva, token, !this.paraCliente).subscribe((resp) => {
      const mbResult: Mbresult = resp.data.aceptarMbreserva;

      if (mbResult.success) {
        this.showSuccess = true;
      } else {
        //enviar mensaje de error
        this.alertServ.sendMsj('danger', this.ts.reservaOk.alerts.acceptReserveError);
      }
    });
  }
}

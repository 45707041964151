<div *ngIf="!showSuccess && !servicio" class="spinner">
  <app-spinner></app-spinner>
</div>

<div class="nav">
  <div class="back-image" [routerLink]="['/profile']"> </div>
</div>

<!-- <div class="figure1">
  <img src="../../../assets/plans/figure1.png">
</div>

<div class="figure2">
  <img src="../../../assets/plans/figure2.png">
</div> -->

<!-- <div class="contenedor" *ngIf="!showSuccess">


  <section class="modify-hour confirm-contact">

    <h2 class="title title-modify"> {{ts?.reserveTime.titleModify}} </h2>

    <div class="content-inputs">
      <p class="subtitle"> {{ts?.reserveTime.subtitleFecha}} </p>

      <div class="inputs">
        <label class="input-label">mm</label>
        <input class="input" #mesInput type="number" value="{{leerMes()}}" />

        <label class="input-label">dd</label>
        <input class="input" #diaInput type="number" value="{{leerDia()}}" />

        <label class="input-label">yyyy</label>
        <input class="input" #anioInput type="number" value="{{leerAnio()}}" />
      </div>
    </div>


    <div class="content-inputs">
      <p class="subtitle"> {{ts?.reserveTime.subtitleHora}} </p>

      <div class="inputs">
        <label class="input-label">HH</label>
        <input class="input" #horaInput type="number" />

        <label class="input-label">Min</label>
        <input class="input" #minutosInput type="number" />
      </div>
    </div>


    <button class="ok-button buttonBasic"
      (click)="guardarNuevaHora(horaInput.value,minutosInput.value, mesInput.value,diaInput.value,anioInput.value)">
      {{ts?.reserveTime.botonGuardar}}
    </button>

  </section>


</div> -->

<div class="calendar" *ngIf="!showSuccess && servicio">
  <app-calendar-cli [service]="servicio" (timeIsSelected)="timeSelected($event)"> </app-calendar-cli>
</div>
<div class="contenedor">
  <!-- === RESERVA EXITOSA === -->

  <div class="reservaOk" *ngIf="showSuccess">
    <img class="reservaOk-Icon" src="../../../assets/service/reserve-ok-2.png" />
    <p class="congratulations"> {{ ts?.reservaOk.congratulations }} </p>
    <p class="reserve-success"> {{ ts?.reserveTime.alerts.changeHourTimeSuccess }} </p>
    <p class="reserve-success"> {{ ts?.reserveTime.espere }} </p>
  </div>
</div>

<app-toast *ngIf="msj" [msj]="msj"></app-toast>

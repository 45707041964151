import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Alert } from '../../core/models/alert.model';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit,OnChanges {
@Input() msj:Alert;
// @Input() type:string;
// @Input() dataMsj:string;
state:Alert;
success:HTMLElement;
info: HTMLElement;
danger: HTMLElement;
warning: HTMLElement;
largo:HTMLCollection;
cont:number=0;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
  this.cont=this.cont+1;
   // if(changes.type)this.state=changes.type.currentValue;
    //console.log(changes.msj.currentValue);
    if (changes.msj) this.state = changes.msj.currentValue;

    this.success= document.getElementById('success');
    this.info = document.getElementById('info');
    this.danger = document.getElementById('danger');
    this.warning = document.getElementById('warning');
    this.success.style.display='none';
    this.info.style.display = 'none';
    this.danger.style.display = 'none';
    this.warning.style.display = 'none';

    //console.log(this.state);
   setTimeout(() => {
     switch (this.state.type) {
       case 'success':
         this.success.style.display = 'flex';
         this.success.style.animation = `success${this.cont} 4000ms cubic-bezier(0.25, 1, 0.5, 1)`;
          setTimeout(() => {
            this.success.style.display='none';
          }, 4000);
         break;
       case 'successFixed':
         this.success.style.display = 'flex';
         this.success.style.animation = `success${this.cont} 4000ms cubic-bezier(0.25, 1, 0.5, 1)`;
         this.success.style.opacity = '1';

         break;
       case 'info':
         this.info.style.display = 'flex';
         this.info.style.animation = `info${this.cont} 4000ms cubic-bezier(0.25, 1, 0.5, 1)`;
         setTimeout(() => {
           this.info.style.display = 'none';
         }, 4000);
         break;
       case 'infoFixed':
         this.info.style.display = 'flex';
         this.info.style.animation = `info${this.cont} 4000ms cubic-bezier(0.25, 1, 0.5, 1)`;
       this.info.style.opacity='1';
         break;
       case 'danger':
         this.danger.style.display = 'flex';
         this.danger.style.animation = `danger${this.cont} 4000ms cubic-bezier(0.25, 1, 0.5, 1)`;
         setTimeout(() => {
           this.danger.style.display = 'none';
         }, 4000);
         break;
       case 'warning':
         this.warning.style.display = 'flex';
         this.warning.style.animation = `warning${this.cont} 4000ms cubic-bezier(0.25, 1, 0.5, 1)`;
         setTimeout(() => {
           this.warning.style.display = 'none';
         }, 4000);
         break;

     }
   }, 100);


  //console.log(changes);
  }


  ngOnInit(): void {

  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { from, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Profile } from '@models/profile';
import { uuidv4 } from '../../utilities';
import { Perfil } from '@models/perfil.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  privateInfo = 'profile_privates';
  publicInfo = 'profiles';
  snapshot = 'profile_snapshots';

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) { }

  createProfile(
    profile: Profile,
    isPublic = true,
    id: string = null
  ): Observable<string> {
    return from(
      new Promise<string>((resolve, reject) => {
        if (isPublic) {
          this.firestore
            .collection<any>(this.publicInfo)
            .add(profile)
            .then((val) => {
              resolve(val.id);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          this.firestore
            .collection<any>(this.privateInfo)
            .doc(id)
            .set(profile)
            .then(() => {
              resolve('ok');
            })
            .catch((err) => {
              reject(err);
            });
        }
      })
    );
  }

  uploadProfilePhoto(file: Blob): Observable<string> {
    return from(
      new Promise<string>((resolve) => {
        if (!file) {
          resolve(null);
        } else {
          const filePath = `prf_photos/${uuidv4()}`;
          const task = this.storage.upload(filePath, file);
          const ref = this.storage.ref(filePath);
          task
            .snapshotChanges()
            .pipe(
            /* finalize(() => {
              ref.getDownloadURL().subscribe((url) => {
                resolve(url);
              });
            }) */
          )
            .subscribe();
        }
      })
    );
  }
  checkAvaibleProfileName(name: string) {
    return this.firestore.collection('profiles', ref => ref.where('fullname', '==', name));
  }
  checkAvaibleRoute(route: string) {
    return this.firestore.collection('profiles', ref => ref.where('route', '==', route));
  }
  updateProfile(
    profile: Profile,
    id: string,
    isPublic = true
  ): Observable<void> {
    console.log('en servicio');
    console.log(profile);
    const collection = isPublic ? this.publicInfo : this.privateInfo;

    return from(this.firestore.collection(collection).doc(id).update(profile));
  }

  getPublicProfile(id: string): Promise<Profile> {
    return this.getProfile(id, this.publicInfo);
  }

  getPrivateProfile(id: string): Promise<Profile> {
    return this.getProfile(id, this.privateInfo);
  }

  getProfileSnapshot(id: string): Promise<Profile> {
    return this.getProfile(id, this.snapshot);
  }
  updateConfig(config: string) {
    const prof = localStorage.getItem('profile');
    this.firestore.collection(this.publicInfo).doc(prof).update({ config: config });

  }
  getPublicAndPrivateProfiles(id: string): Observable<Profile> {
    const promise = new Promise<Profile>((resolve, reject) => {
      this.getPublicProfile(id).then(
        (publicProf) => {
          this.getPrivateProfile(id).then(
            (privateProf) => {
              resolve(this.mergeProfiles(publicProf, privateProf));
            },
            () => {
              resolve(publicProf);
            }
          );
        },
        (err) => {
          reject(err);
        }
      );
    });

    return from(promise);
  }

  private mergeProfiles(a: Profile, b: Profile): Profile {
    const p: any = {};
    Object.keys(a).forEach((key) => {
      p[key] = a[key];
    });
    Object.keys(b).forEach((key) => {
      p[key] = b[key];
    });
    p.links = [...a.links, ...b.links];
    return p as Profile;
  }

  getProfileStats(profileId: string): Observable<any> {
    //console.log('en servicio: id: '+profileId);
    return this.firestore
      .collection<any>('stats', (ref) =>
        ref.where('profileId', '==', profileId)
      )
      .valueChanges();
  }

  deleteProfile(id: string) {
    return this.firestore.collection(this.publicInfo).doc(id).delete();
  }

  private async getProfile(id: string, collection: string): Promise<Profile> {
    return await lastValueFrom(this.firestore
      .collection(collection)
      .doc(id)
      .get()
      .pipe(map((profile) => profile.data() as Profile)));
  }
  otherGetProfile(id: string) {
    return this.firestore.collection<Profile>('profile').doc(id).get();
  }

  crearPerfil(perfil: Perfil, token: string): Observable<any> {
    var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", environment.apiKey);

    const query = `mutation createMbperfil($createMbperfilInput:CreateMbperfilInput!){
  createMbperfil(createMbperfilInput:$createMbperfilInput){
    success
    message
  }
}`;

    const variables = {
      ...perfil
    }
    return this.http.post(environment.urlGraphql, { query, variables }, { headers })
  }
  subirImagen(file: Blob, token: string): Observable<any> {
    var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", environment.apiKey);
    const url = environment.urlFileUpload;

    const formulario = new FormData();

    formulario.append('bucketName', 'januus/perfil');
    formulario.append('upload', file);

    return this.http.post(url, formulario, { headers });


  }
  subirArchivos(file: Blob, token: string): Observable<any> {
    var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", environment.apiKey);
    const url = environment.urlFileUpload;

    const formulario = new FormData();

    formulario.append('bucketName', 'januus/articulo');
    formulario.append('upload', file);

    return this.http.post(url, formulario, { headers });


  }
  subirPortadaServicio(file: Blob, token: string): Observable<any> {
    var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", environment.apiKey);
    const url = environment.urlFileUpload;

    const formulario = new FormData();

    formulario.append('bucketName', 'januus/servicio');
    formulario.append('upload', file);

    return this.http.post(url, formulario, { headers });


  }
}

import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@helpers/shared.helpers';
import { SettingsState } from '../models/settings-state.model';

const fileName = 'settings';
export const SET_BROWSER = createAction(
  '[Settings] Set browser',
  props<{ currentBrowser: string }>(),
);

export const SET_APP_VERSION = createAction(
  buildStringNameForAction(fileName, 'Set version of system'),
  props<{ appVersion: string }>(),
);

export const SET_APPLICATION_CONFIGURATION = createAction(
  '[Settings] Set application configuration',
  props<{ appSettings: SettingsState }>(),
);

export const SET_PUBLIC_IP = createAction(
  '[Settings] Set public IP',
  props<{ publicIp: string }>(),
);

export const setAppInitializationLoad = createAction(
  buildStringNameForAction(fileName, 'Set application initialization load'),
);
export const setAppInitializationComplete = createAction(
  buildStringNameForAction(fileName, 'Set application initialization complete'),
);

export const setNavigatorLanguage = createAction(
  buildStringNameForAction(fileName, 'Set navigator language'),
  props<{ language: string }>(),
);
export const emptyAction = createAction(buildStringNameForAction(fileName, 'Empty action'));

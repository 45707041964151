import { Subscription } from "rxjs";
import { NotificationService } from "./services/notification.service";

export class Messaging {


  notifyProccess: Subscription;



  constructor(private notifyServ: NotificationService) { }


  // sendNotify(userId: string, title: string = 'Januus', body: string, image?: string, link: string = 'https://app.januus.com/profile/notification') {
  //   /* this.notifyProccess =  */this.notifyServ.getUserInfo(userId).then(data => {
  //     // console.log(data.data().token);
  //     const token = data.data().token

  //     this.notifyServ.sendData(token, title, body, image, link).subscribe(data => {
  //       console.log(data)
  //     });
  //   })
  // }
}
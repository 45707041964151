import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import {ReservaModel} from '../models/reserva.model';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReservaService {
  constructor(private http: HttpClient) {}

  crearReserva(reserva: ReservaModel, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation createMbreserva($createMbreservaInput:CreateMbreservaInput!){
  createMbreserva(createMbreservaInput:$createMbreservaInput){
    success
    message
    mbReserva{
    _id
    }
  }
}`;

    const variables = {
      createMbreservaInput: {
        ...reserva,
      },
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  leerReservaPorPerfil(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorPerfilProfesionalSolicitada($id:ID!){
  mbreservaPorPerfilProfesionalSolicitada(id:$id){
    _id
    correo
    direccion
    fechaHoraReserva
    fechaHoraReagendoCliente
    fechaHoraReagendoProfesional
		mbServicio{
      _id
      servicio
      listaDisponibilidad {
        dia
        horaInicio
        horaFin
      }

    }
    mbPerfil {
      imagen
    }
    nombre
    notas
    telefono
  }
}`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  leerReservasSolicidasProximas(id: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbreservaPorClienteSolicitadaProxima($id:ID!){
      mbreservaPorClienteSolicitadaProxima(id:$id){
        _id
        correo
        direccion
        fechaHoraRegistro
        fechaHoraReserva
        fechaHoraReagendoCliente
        fechaHoraReagendoProfesional
        fechaHoraRechazoCliente
        fechaHoraRechazoProfesional
        mbEstado{
          catalogo
          descripcion
        }
        mbServicio{
          _id
          servicio
          imagenPortada
          permitirConfirmarReserva
          listaDisponibilidad {
            dia
            horaInicio
            horaFin
          }

        }
        mbPerfil {
          imagen
          nombre
        }
        mbPerfilProfesional {
          imagen
          nombre
        }
        mbCliente{
          _id
        }
        nombre
        notas
        telefono
      }
    }`;

    const variables = {
      id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers}).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => new Error(error));
      }),
    );
  }
  aceptarReserva(idReserva: string, token: string, esProfesional: boolean): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation aceptarMbreserva($esProfesional:Boolean!, $id:ID!){
  aceptarMbreserva(esProfesional:$esProfesional, id:$id){
    message
    success
  }
}`;

    const variables = {
      esProfesional: esProfesional,
      id: idReserva,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  rechazarReserva(idReserva: string, token: string, esProfesional: boolean): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation rechazarMbreserva($esProfesional:Boolean!, $id:ID!){
  rechazarMbreserva(esProfesional:$esProfesional, id:$id){
    message
    success
  }
}`;

    const variables = {
      esProfesional: esProfesional,
      id: idReserva,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  cambiarFechaHora(fecha: Date, id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation cambiarFechaHoraMbreserva($fechaHoraReserva:DateTime!, $id:ID!){
  cambiarFechaHoraMbreserva(fechaHoraReserva:$fechaHoraReserva, id:$id){
    message
    success
  }
}`;

    const variables = {
      fechaHoraReserva: fecha,
      id: id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  leerReservaPorEstado(idPerfil: string, estado: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorPerfilProfesionalEstado($id:ID! $idEstado:ID!){
  mbreservaPorPerfilProfesionalEstado(id:$id idEstado:$idEstado){
    _id
    correo
    direccion
    fechaHoraReserva
		mbServicio{
      servicio
    }
  mbCliente{
      _id

    }
  mbPerfilProfesional{
    _id
    nombre
    listaInfoIdioma{
      profesion
    }

  }
    nombre
    notas
    telefono
  }
}`;

    const variables = {
      id: idPerfil,
      idEstado: estado,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  leerReservasPorCliente(idCliente: string, estado: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteEstado($id:ID! $idEstado:ID!){
  mbreservaPorClienteEstado(id:$id idEstado:$idEstado){
    _id
    correo
    direccion
    fechaHoraReserva
		mbServicio{
      servicio
    }
    mbCliente{
      _id

    }
    mbPerfilProfesional{
    _id
    nombre
    imagen
    listaInfoIdioma{
      profesion
    }

  }
    nombre
    notas
    telefono
  }
}`;

    const variables = {
      id: idCliente,
      idEstado: estado,
    };
    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  reservaPorClienteEstado(idCuenta: string, idEstado: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteEstado($id:ID! $idEstado:ID!){
  mbreservaPorClienteEstado(id:$id idEstado:$idEstado){
    _id
    nombre
    calificacionCliente
    calificacionProfesional
    observacionCliente
    observacionProfesional
    mbPerfilProfesional{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio
      listaDisponibilidad {
        dia
        horaInicio
        horaFin
      }

    }
    fechaHoraReserva
    mbCliente{
      _id
      nombres
      apellidos
      correo
      imagen
    }

  }

}`;

    const variables = {
      id: idCuenta,
      idEstado: idEstado,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteEstadoProfesional(
    idCuenta: string,
    idEstado: string,
    token: string,
  ): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteEstadoProfesional($id:ID! $idEstado:ID!){
  mbreservaPorClienteEstadoProfesional(id:$id idEstado:$idEstado){
    _id
    nombre
    calificacionCliente
    calificacionProfesional
    observacionCliente
    observacionProfesional
    mbPerfilProfesional{
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio
      listaDisponibilidad {
        dia
        horaInicio
        horaFin
      }

    }
    fechaHoraReserva
    mbCliente{
      _id
      nombres
      apellidos
      correo
      imagen
    }

  }

}`;

    const variables = {
      id: idCuenta,
      idEstado: idEstado,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteCompletada(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteCompletada($id:ID!){
  mbreservaPorClienteCompletada(id:$id){
    _id
    nombre
    calificacionCliente
    calificacionProfesional
    observacionCliente
    observacionProfesional
    mbPerfilProfesional{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio
      listaDisponibilidad {
        dia
        horaInicio
        horaFin
      }

    }
    fechaHoraReserva
    mbCliente{
      _id
      imagen
      correo
      nombres
      apellidos

    }

  }

}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteCompletadaProfesional(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteCompletadaProfesional($id:ID!){
  mbreservaPorClienteCompletadaProfesional(id:$id){
    _id
    nombre
    calificacionCliente
    calificacionProfesional
    observacionCliente
    observacionProfesional
    mbPerfilProfesional{
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio
      listaDisponibilidad {
        dia
        horaInicio
        horaFin
      }

    }
    fechaHoraReserva
    mbCliente{
      _id
      correo
      imagen
      nombres
      apellidos
    }

  }

}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteProxima(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteProxima($id:ID!){
  mbreservaPorClienteProxima(id:$id){
    nombre
    _id
    mbPerfilProfesional{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      _id
      servicio
      listaDisponibilidad{
        dia
        horaInicio
        horaFin
      }

    }
    fechaHoraReserva
    fechaHoraReagendoCliente
    fechaHoraReagendoProfesional
    fechaHoraRegistro
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteProximaProfesional(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteProximaProfesional($id:ID!){
  mbreservaPorClienteProximaProfesional(id:$id){
    nombre
    _id
    mbPerfilProfesional{
      imagen
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      _id
      servicio
      listaDisponibilidad{
        dia
        horaInicio
        horaFin
      }

    }
    fechaHoraReserva
    fechaHoraReagendoCliente
    fechaHoraReagendoProfesional
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteIniciadas(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClientePendiente($id:ID!){
  mbreservaPorClientePendiente(id:$id){
    nombre
    _id
    mbPerfilProfesional{
      _id
      imagen
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      _id
      servicio
      listaDisponibilidad{
        dia
        horaInicio
        horaFin
      }
    }
    fechaHoraReserva
    fechaHoraReagendoProfesional
    fechaHoraReagendoCliente
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteIniciadasProfesional(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClientePendienteProfesional($id:ID!){
      mbreservaPorClientePendienteProfesional(id:$id){
    nombre
    _id
    mbPerfilProfesional{
      imagen
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      _id
      servicio
      listaDisponibilidad{
        dia
        horaInicio
        horaFin
      }
    }
    fechaHoraReserva
    fechaHoraReagendoProfesional
    fechaHoraReagendoCliente
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  reservaPorClientePendientePago(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClientePendientePago($id:ID!){
  mbreservaPorClientePendientePago(id:$id){
    nombre
    _id
    mbPerfilProfesional{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio

    }
    fechaHoraReserva
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClientePendientePagoProfesional(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClientePendientePagoProfesional($id:ID!){
    mbreservaPorClientePendientePagoProfesional(id:$id){
    nombre
    _id
    mbPerfilProfesional{
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio

    }
    fechaHoraReserva
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteReportada(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteReportada($id:ID!){
  mbreservaPorClienteReportada(id:$id){
    nombre
    _id
    reporte
    tituloReporte
    listaArchivoReporte
    mbPerfilProfesional{
      _id
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio

    }
    fechaHoraReserva
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reservaPorClienteReportadaProfesional(idCuenta: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorClienteProfesionalReportada($id:ID!){
      mbreservaPorClienteProfesionalReportada(id:$id){
    nombre
    _id
    reporte
    tituloReporte
    listaArchivoReporte
    mbPerfilProfesional{
      nombre
      imagen
      listaInfoIdioma{
        profesion
      }
    }
    mbPerfil{
      _id
      nombre
      listaInfoIdioma{
        profesion
      }
    }
    mbServicio{
      servicio

    }
    fechaHoraReserva
    mbCliente{
      _id

    }

  }


}`;

    const variables = {
      id: idCuenta,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  finalizarReserva(
    idReserva: string,
    calificacion: number,
    esProfesional: boolean,
    observaciones: string,
    token: string,
  ): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation finalizarMbreserva($calificacion:Float $esProfesional:Boolean! $id:ID! $observacion:String ){
  finalizarMbreserva(calificacion:$calificacion esProfesional:$esProfesional id:$id observacion:$observacion){

    message
    success
  }

}`;

    const variables = {
      calificacion: calificacion,
      esProfesional: esProfesional,
      id: idReserva,
      observacion: observaciones,
    };
    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  leerReservasAceptadas(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreservaPorPerfilProfesionalAceptado($id:ID!){
  mbreservaPorPerfilProfesionalAceptada(id:$id ){
      _id
    correo
    direccion
    fechaHoraReserva
		mbServicio{
      servicio
    }
  mbCliente{
      _id
    imagen
    nombres
    apellidos
    correo
    }
  mbPerfil{
    nombre
    imagen
    listaInfoIdioma{
      profesion
    }
  }
  mbPerfilProfesional{
    _id
    nombre
    imagen
    listaInfoIdioma{
      profesion
    }

  }
    nombre
    notas
    telefono
  }
}`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  leerReserva(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbreserva($id:ID!){
  mbreserva(id:$id ){
    message
    success
  mbReserva{
    _id
    correo
    direccion
    fechaHoraReserva
    fechaHoraRegistro
    notas
    nombre
    telefono
    mbServicio{
      _id
      servicio
      listaDisponibilidad{
        dia
        horaFin
        horaInicio

      }
    }
    mbCliente{
      _id
    }
mbPerfilProfesional{
  _id
  nombre
  direccion
  ciudad
  pais
  listaInfoIdioma{
    profesion
  }
}
  }
  }
}
`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  reservaPorServicio(idServicio: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbreservaPorServicioCalificacion($idServicio:ID!){
    mbreservaPorServicioCalificacion(idServicio:$idServicio){
      calificacionCliente
      calificacionProfesional
      observacionCliente
      observacionProfesional
      mbCliente{
        nombres
        correo
        apellidos
        imagen

      }
      mbPerfil{
        nombre
        imagen
        listaInfoIdioma{
          profesion
        }
      }
    }
  }`;

    const variables = {
      idServicio: idServicio,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  horasOcupadas(fecha: Date, idServicio: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbreservaPorServicioOcupado($fecha:DateTime! $idServicio:ID!){

  mbreservaPorServicioOcupado(fecha:$fecha idServicio:$idServicio){
fechaHoraReserva
  }
}`;
    const variables = {
      fecha: fecha,
      idServicio: idServicio,
    };
    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  // reportarReserva(idReserva:string, listaArchivo:string[], reporte:string, token:string):Observable<any>{
  //   var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", "ca028a58-b146-41ca-ab5b-65e29f1e0bab");

  //   const query =`mutation reportarMbreserva($id:ID! $listaArchivoReporte:[String!] $reporte:String!){
  //   reportarMbreserva(id:$id listaArchivoReporte:$listaArchivoReporte reporte:$reporte){
  //     success
  //     message

  //   }
  // }`;

  //   const variables = {
  //     "id": idReserva,
  //     "listaArchivoReporte": listaArchivo,
  //     "reporte": reporte
  //   }

  //   return this.http.post("https://api.januus.com/graphql", { query, variables }, { headers })
  // }
  reportarReserva(
    idReserva: string,
    listaArchivo: string[],
    reporte: string,
    tituloReporte: string,
    token: string,
  ): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation reportarMbreserva($id:ID! $listaArchivoReporte:[String!] $reporte:String! $tituloReporte:String!){
  reportarMbreserva(id:$id listaArchivoReporte:$listaArchivoReporte reporte:$reporte tituloReporte:$tituloReporte){
    success
    message

  }
}`;

    const variables = {
      id: idReserva,
      listaArchivoReporte: listaArchivo,
      reporte: reporte,
      tituloReporte: tituloReporte,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  reportarReservaProfesional(
    idReserva: string,
    listaArchivo: string[],
    reporte: string,
    tituloReporte: string,
    token: string,
  ): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation reportarProfesionalMbreserva($id:ID! $listaArchivoReporte:[String!] $reporte:String! $tituloReporte:String!){
  reportarProfesionalMbreserva(id:$id listaArchivoReporte:$listaArchivoReporte reporte:$reporte tituloReporte:$tituloReporte){
    success
    message

  }
}`;

    const variables = {
      id: idReserva,
      listaArchivoReporte: listaArchivo,
      reporte: reporte,
      tituloReporte: tituloReporte,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  subirArchivoReporte(file: Blob, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const url = environment.urlFileUpload;

    const formulario = new FormData();

    formulario.append('bucketName', 'januus/reserva');
    formulario.append('upload', file);

    return this.http.post(url, formulario, {headers});
  }
  cambiarFechaHoraReserva(idReserva: string, fechaHora: Date, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation cambiarFechaHoraPorClienteMbreserva($fechaHoraReserva:DateTime! $id:ID!){
  cambiarFechaHoraPorClienteMbreserva(fechaHoraReserva:$fechaHoraReserva id:$id){
    success
    message
  }
}`;

    const variables = {
      fechaHoraReserva: fechaHora,
      id: idReserva,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  cambiarFechaHoraReservaProfesional(
    idReserva: string,
    fechaHora: Date,
    token: string,
  ): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation cambiarFechaHoraMbreserva($fechaHoraReserva:DateTime! $id:ID!){
  cambiarFechaHoraMbreserva(fechaHoraReserva:$fechaHoraReserva id:$id){
    success
    message
  }
}`;

    const variables = {
      fechaHoraReserva: fechaHora,
      id: idReserva,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  crearPagoReserva(amount: number, currency: string, reserva: ReservaModel, token: string) {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKeyPay);

    return this.http.post<any>(
      environment.urlPayments + '/stripe/crearPago',
      {amount: amount, currency: currency, metadata: {...reserva}},
      {headers},
    );
  }
  pagar(paymentIntent: string, form: any, total: number, token: string, accountId: string = '') {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKeyPay);

    return this.http.post<any>(
      environment.urlPayments + '/stripe/pagar',

      {
        intentId: paymentIntent,
        nameCard: form.name,
        numberCard: form.number,
        expireMonth: form.expireMonth,
        expireYear: form.expireYear,
        codeCard: form.cardCode,
        total: total,
      },
      {headers},
    );

    // if(accountId=''){

    // }else{

    //   return this.http.post<any>('https://payment.januus.com/stripe/pagar',

    //     {
    //       intentId: paymentIntent,
    //       nameCard: form.name,
    //       numberCard: form.number,
    //       expireMonth: form.expireMonth,
    //       expireYear: form.expireYear,
    //       codeCard: form.cardCode,
    //       accountId:accountId,
    //       total: total
    //     }, { headers })

    // }
  }

  reservasPagada(idCliente:string,token:string):Observable<any>{
    var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", environment.apiKey);
    const query =`query mbreservaPagadaPorCliente($idCliente:ID!){
      mbreservaPagadaPorCliente(idCliente:$idCliente){
        _id
        nombre
        fechaHoraReserva
        mbServicio{
          servicio
          precio
          divisa

        }
        mbCliente{
          _id
        }
        mbPerfilProfesional{
          nombre
        }


      }
    }`;
    const variables = {
      "idCliente": idCliente
    }
    return this.http.post(environment.urlGraphql, { query, variables }, { headers })
  }
  reservasPagadaProfesional(idCliente:string,token:string):Observable<any>{
    var headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("x-api-key", environment.apiKey);
    const query =`query mbreservaPagadaPorClienteProfesional($idCliente:ID!){
      mbreservaPagadaPorClienteProfesional(idCliente:$idCliente){
        _id
        nombre
        fechaHoraReserva
        mbServicio{
          servicio
          precio
          divisa

        }
        mbCliente{
          _id
        }
        mbPerfilProfesional{
          nombre
        }


      }
    }`;
    const variables = {
      "idCliente": idCliente
    }
    return this.http.post(environment.urlGraphql, { query, variables }, { headers })
  }
}

import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { selectSecretKey } from '@settings-module/store/settings.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor(private store: Store) {}

  private async getKey(password: string): Promise<CryptoKey> {
    const enc = new TextEncoder();
    const keyMaterial = enc.encode(password);

    // Derivar la clave usando PBKDF2 con el mismo 'salt' y longitud de clave (32 bytes para AES-256)
    return crypto.subtle.importKey('raw', keyMaterial, { name: 'PBKDF2' }, false, ['deriveKey']);
  }

  private async deriveKey(keyMaterial: CryptoKey): Promise<CryptoKey> {
    return crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: new TextEncoder().encode('salt'), // Mismo 'salt' que en backend
        iterations: 100000, // Asegúrate de que el backend también use las mismas iteraciones
        hash: 'SHA-256',
      },
      keyMaterial,
      { name: 'AES-CTR', length: 256 }, // AES-256
      false,
      ['encrypt', 'decrypt'],
    );
  }

  async encrypt(data: string): Promise<string> {
    const password = await firstValueFrom(this.store.select(selectSecretKey));

    const keyMaterial = await this.getKey(password);

    const key = await this.deriveKey(keyMaterial);
    const iv = crypto.getRandomValues(new Uint8Array(16)); // Generar un IV aleatorio de 16 bytes

    const encryptedData = await crypto.subtle.encrypt(
      {
        name: 'AES-CTR',
        counter: iv, // Mismo IV que en el backend
        length: 64,
      },
      key,
      new TextEncoder().encode(data),
    );

    // Retornar IV en hex y los datos encriptados en base64
    return `${this.arrayBufferToHex(iv)}:${this.arrayBufferToBase64(encryptedData)}`;
  }

  async decrypt(encryptedData: string): Promise<string> {
    const password = await firstValueFrom(this.store.select(selectSecretKey));

    const [ivHex, encryptedBase64] = encryptedData.split(':');
    const iv = this.hexToArrayBuffer(ivHex);
    const encryptedArrayBuffer = this.base64ToArrayBuffer(encryptedBase64);

    const keyMaterial = await this.getKey(password);
    const key = await this.deriveKey(keyMaterial);

    const decryptedData = await crypto.subtle.decrypt(
      {
        name: 'AES-CTR',
        counter: iv, // Mismo IV que en el backend
        length: 64,
      },
      key,
      encryptedArrayBuffer,
    );

    return new TextDecoder().decode(decryptedData);
  }

  // Helper para convertir ArrayBuffer a Hex
  private arrayBufferToHex(buffer: ArrayBuffer): string {
    return Array.prototype.map
      .call(new Uint8Array(buffer), (x: number) => ('00' + x.toString(16)).slice(-2))
      .join('');
  }

  // Helper para convertir ArrayBuffer a Base64
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
  }

  // Helper para convertir Hex a ArrayBuffer
  private hexToArrayBuffer(hex: string): ArrayBuffer {
    const typedArray = new Uint8Array(hex.match(/[\da-f]{2}/gi)!.map((h) => parseInt(h, 16)));
    return typedArray.buffer;
  }

  // Helper para convertir Base64 a ArrayBuffer
  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}

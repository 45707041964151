import { createSelector } from '@ngrx/store';
import {
  AppSettings,
  Price,
  Prices,
  SettingsState,
  VerifiPayment,
} from '../models/settings-state.model';
import { selectSettingsState } from '@core/store.state';
import { find } from 'lodash-es';
import { AccountTypesString } from '@helpers/core.helpers';

export const selectSettings = createSelector(selectSettingsState, (state: SettingsState) => state);

export const selectInitializationComplete = createSelector(
  selectSettings,
  (state: SettingsState): boolean => state.initializationComplete,
);

export const selectAppSettings = createSelector(
  selectSettings,
  (state: SettingsState): AppSettings => state.appSettings,
);

export const selectSignalAppId = createSelector(
  selectAppSettings,
  (state: AppSettings): string => state?.signalAppId,
);
export const selectVerifyPayment = createSelector(
  selectAppSettings,
  (state: AppSettings): VerifiPayment => state?.verifyPayment,
);
export const selectVerifyPaymentPrices = createSelector(
  selectVerifyPayment,
  (state: VerifiPayment): Prices => state?.prices,
);
export const selectVerifyPaymentIndividualPrice = createSelector(
  selectVerifyPaymentPrices,
  (state: Prices): Price => find(state, (o: Price) => o.name === AccountTypesString.INDIVIDUAL),
);
export const selectVerifyPaymentBusinessPrice = createSelector(
  selectVerifyPaymentPrices,
  (state: Prices): Price => find(state, (o: Price) => o.name === AccountTypesString.ENTERPRISE),
);
export const selectVerifyPaymentBothPrice = createSelector(
  selectVerifyPaymentPrices,
  (state: Prices): Price => find(state, (o: Price) => o.name === AccountTypesString.BOTH),
);

export const selectSecretKey = createSelector(
  selectAppSettings,
  (state: AppSettings): string => state?.secretKey,
);

export const selectPublicIp = createSelector(
  selectSettings,
  (state: SettingsState): string => state.publicIp,
);

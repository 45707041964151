import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

import { ClientState } from '@client-module/models/client-state.model';
import { setTokenFirebaseClientLoad } from '@client-module/store/client.actions';
import { selectClient } from '@client-module/store/client.selectors';
import { Mbcliente } from '@core/models/mb-articulo-comentario.model';
import { AppState } from '@core/store.state';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, lastValueFrom, mergeMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject<any>(null);
  //private tokenDeleted = false;
  constructor(private afMessaging: AngularFireMessaging, private store: Store<AppState>) { 
  
  
    

  }

  async requestPermission() {
    //this.clientState.client = await lastValueFrom(this.store.pipe(select(selectClient), take(1)));
    this.afMessaging.requestToken.subscribe(
      (token) => {
        console.log('Permiso concedido y token recibido:', token);
        // Aquí puedes almacenar el token en tu base de datos para enviarlo a los usuarios
        
        this.store.dispatch(setTokenFirebaseClientLoad({tokenFirebase: token}))
      },
      (error) => {
        console.error('Permiso denegado o error al obtener el token:', error);
      }
    );
  }

  receiveMessage() {
    this.afMessaging.messages.subscribe((message) => {
      //console.log('Mensaje recibido:', message);
      this.currentMessage.next(message);
    });
  }
  deleteToken() {
    this.afMessaging.getToken
      .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
      .subscribe(
        (token) => {
          console.log('Token deleted!');
          
          

          // localStorage.removeItem('notify');
          //this.state.emit(false);
          //this.NotiFyS.removeToken(this.user.id);
        },
      );
  }
  deleteTokenOther() {
    this.afMessaging.getToken
      .pipe(
        mergeMap(token => this.afMessaging.deleteToken(token))
      )
      .subscribe({
        next: () => {
          console.log('Token deleted!');
          //localStorage.removeItem('notify'); // Asegúrate de que el token también se elimine de localStorage
          //this.state.emit(false);
          //this.NotiFyS.removeToken(this.user.id); // Llama a tu servicio para eliminar el token del backend si es necesario
        },
        error: (err) => {
          console.error('Error al eliminar el token:', err);
        }
      });
  }
  async hasToken(): Promise<boolean> {
    try {
      const token = await lastValueFrom(this.afMessaging.getToken);
      console.log('tiene token', token);
      return !!token; // Retorna true si hay token, false si no lo hay
    } catch (error) {
      console.error("Error al obtener el token:", error);
      return false;
    }
  }
  // async deleteToken(): Promise<boolean> {
  //   try {
  //     const token = await lastValueFrom(this.afMessaging.getToken);
  //     if (token) {
  //       await lastValueFrom(this.afMessaging.deleteToken(token));
  //       console.log('Token eliminado correctamente');
  //       localStorage.removeItem('notify'); // Limpia el token del almacenamiento
  //       this.tokenDeleted = true; // Marca el token como eliminado
  //       return true;
  //     } else {
  //       console.log('No se encontró un token para eliminar');
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error('Error al eliminar el token:', error);
  //     return false;
  //   }
  // }

  // async hasToken(): Promise<boolean> {
  //   if (this.tokenDeleted) {
  //     console.log("El token ha sido eliminado previamente.");
  //     return false; // Si ya fue eliminado, no intenta regenerarlo
  //   }

  //   try {
  //     const token = await lastValueFrom(this.afMessaging.getToken);
  //     return !!token; // Retorna true si hay un token, false si no
  //   } catch (error) {
  //     console.error('Error al verificar el token:', error);
  //     return false;
  //   }
  // }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'http://localhost:4200',
  name: 'dev',
  production: false,
  firebase: {
    apiKey: 'AIzaSyBa5lwDOtwYVgb7jOLgt7YIvoKJT6C25_o',
    authDomain: 'januus-82690.firebaseapp.com',
    projectId: 'januus-82690',
    storageBucket: 'januus-82690.appspot.com',
    messagingSenderId: '798978118081',
    appId: '1:798978118081:web:0638b052f9e421235d9f40',
    measurementId: 'G-FHPBS82QCF'
  },
  firebase2: {
    apiKey: 'AIzaSyAuGrBSs4aZMlpxul9Nl2P4DvoGhMoq3cs',
    authDomain: 'contactame-app.firebaseapp.com',
    databaseURL: 'https://contactame-app.firebaseio.com',
    projectId: 'contactame-app',
    storageBucket: 'contactame-app.appspot.com',
    messagingSenderId: '627772291740',
    appId: '1:627772291740:web:bf599ed242c2071c158b59',
    measurementId: 'G-GZMVDHJ453',
  },
  search: {
    host: 'https://buscarsearch.contactame.app',
    apiKey: '(*m9:gv>av8N_B=j'
  },
  stripe:
    'pk_live_51HdQsaHaq2d0bH3Ir25htHynKen8riDBvN4HnwLarjEcoIZc27pYIS70irQv8TQxlEcZCGT8NCRY6KIgCw1qygGs007zyUpcDB',
  mercadopago: 'APP_USR-3e09aa31-490a-4460-9ebb-927d13166d3c',
  key: 'Key=AAAAkiok-pw:APA91bHW8_JdVB17wNrcp2Adq_f5BpoWfsmFPqb-x9fE6hJFQYAoJsChKqlStCspkWTuNHtVeiexak1zzOpX12XsNtjtWk3fp-gSEI0mm1uykz9ki-jENcwDut849yYJJecPw1OFNnCp',
  calendar: '627772291740-6aqtmggl6m2vseiqfrf2o5o9tge3bnet.apps.googleusercontent.com',
  apiKey: 'ca028a58-b146-41ca-ab5b-65e29f1e0bab',
  apiKeyPay: 'e4dc80d1-fefc-4ab2-b741-ff0f265c01d7',
  apiKeySocket: 'b9320d19-0dad-43cc-936a-e7a4eca323f9',
  urlGraphql: 'https://api-test.januus.com/graphql',
  urlPayments: 'https://payment-test.januus.com',
  urlFileUpload: 'https://escanor.mejorseguridad.com/archivo/subir',
  urlSocket: 'https://socket-test.januus.com'

  
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

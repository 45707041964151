import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BusyTime, SelectedCita, TimeOfCalendar} from 'src/app/core/models/calendar.model';
import {BookedDates} from 'src/app/core/models/profile';
import {ReservaResponse} from 'src/app/core/models/reserva.model';
import {Disponibilidad, ServicioModel} from 'src/app/core/models/servicio.model';
import {LoadjsonService} from 'src/app/core/services/loadjson.service';
import {ReservaService} from 'src/app/core/services/reserva.service';
import {firstValueFrom} from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store.state';
import { selectClientToken } from '@auth-module/store/auth.selectors';

enum Step {
  Date = 1,
  Time = 2,
}

interface DayItem {
  day: number;
  color?: string;
  fontWeight?: string;
  animation?: string;
}
@Component({
  selector: 'app-calendar-cli',
  templateUrl: './calendar-cli.component.html',
  styleUrls: ['./calendar-cli.component.scss'],
})
export class CalendarCliComponent {
  @Output() timeIsSelected: EventEmitter<SelectedCita> = new EventEmitter<SelectedCita>();
  @Input() citas: BookedDates[];
  @Input() service: ServicioModel;

  steps = Step;
  calendarCurrentMonth = '';
  calendarCurrentYear;
  todayDateMonth = '';
  todayDateYear;
  ts: any;
  currentMonthFirstDate: Date = new Date();
  newDate: Date;
  lastMonthCalendarDays: DayItem[] = [];
  currentMonthCalendarDays: DayItem[] = [];
  todayNumberDay: DayItem;
  reserved: Date[];
  time: boolean;
  currentStep: Step = Step.Date;
  loadDays: boolean;
  loadTime: boolean;
  busyTimes: BusyTime[] = [];
  timeOfCalendar: TimeOfCalendar[] = [];
  selectedCita: SelectedCita = new SelectedCita();
  diasDisponibles: number[] = [];
  fechaReservas: ReservaResponse[];
  horaSeleccionada: FormControl;
  todayDate: Date = new Date();
  token:string;

  constructor(public translate: LoadjsonService,
             private reservaServ: ReservaService,
             private store:Store<AppState>) {
    this.translate.loadCalendar().subscribe((resp) => {
      this.ts = resp;
      this.initCalendar();
    });

    this.store.select(selectClientToken).subscribe(resp=>{
     this.token= resp;
    })
    this.horaSeleccionada = new FormControl('');
  }

  back(): void {
    this.currentStep = Step.Date;
    this.initCalendar();
  }

  async selectDay(day: DayItem): Promise<void> {
    this.timeOfCalendar = [];
    this.newDate = new Date(); // Para que?
    //DOCS: Se setea el dia dos veces por el problema del dia 31
    this.newDate.setDate(day.day);
    this.newDate.setFullYear(this.currentMonthFirstDate.getFullYear());
    this.newDate.setMonth(this.currentMonthFirstDate.getMonth());
    this.newDate.setDate(day.day);
    this.newDate.setHours(0, 0, 0, 0);

    const hoy: Date = new Date();

    //DOCS: PARA MOSTRAR LOS HORARIOS DE LOS DIAS DISPONIBLES
    if (this.diasDisponibles.includes(this.newDate.getTime())) {
      this.time = true;
      this.currentStep = this.steps.Time;
      this.loadTime = true;
    }

    this.selectedCita.day = this.newDate;

    // DOCS: cargo los horarios ocupados ese dia
    await this.loadBusyTime(this.newDate);

    const result: Disponibilidad[] = this.service.listaDisponibilidad.filter(
      (el: Disponibilidad): boolean => el.dia === this.newDate.getDay().toString(),
    );

    if (result.length > 0) {
      const separateTime = result[0].horaInicio.toString();
      const timeLst = separateTime.split(':');
      const hour = Number(timeLst[0]);
      let min = Number(timeLst[1]);

      // DOCS: separo hora de end time
      const separateEndTime = result[0].horaFin.toString();
      const endTimeLst = separateEndTime.split(':');
      const endHour = Number(endTimeLst[0]);

      for (let index = hour; index <= endHour; index++) {
        const res: BusyTime[] = this.busyTimes.filter((el: BusyTime): boolean => el.hour === index);

        if (res.length < 1) {
          // DOCS: si fechas son iguales no agrego las horas que ya pasaron
          if (this.fechasSonIguales(hoy, this.newDate)) {
            // DOCS: aca se deberia tener en cuenta la duración para mostrar las horas disponibles
            if (hoy.getHours() < index) {
              this.timeOfCalendar.push({hour: index, min: 0});
              min = 0;
              if (index < endHour) {
                for (let index2 = 1; index2 < 4; index2++) {
                  min = min + 15;
                  this.timeOfCalendar.push({hour: index, min});
                }
              }
            }
          } else {
            this.timeOfCalendar.push({hour: index, min: 0});
            min = 0;
            if (index < endHour) {
              for (let index2 = 1; index2 < 4; index2++) {
                min = min + 15;
                this.timeOfCalendar.push({hour: index, min});
              }
            }
          }
        }
      }
    }
    this.loadTime = false;
  }

  fechasSonIguales(fecha1: Date, fecha2: Date): boolean {
    const esMismoAnio: boolean = fecha1.getFullYear() === fecha2.getFullYear();
    const esMismoMes: boolean = fecha1.getMonth() === fecha2.getMonth();
    const esMismoDia: boolean = fecha1.getDate() === fecha2.getDate();

    return esMismoAnio && esMismoMes && esMismoDia;
  }

  async loadBusyTime(dat: Date): Promise<void> {
    this.busyTimes = [];

    //const userData = JSON.parse(localStorage.getItem('user-data'));
    if (this.token) {
      const resp = await firstValueFrom(
        this.reservaServ.horasOcupadas(dat, this.service._id, this.token),
      );

      this.fechaReservas = resp.data.mbreservaPorServicioOcupado;
      if (this.fechaReservas.length) {
        this.fechaReservas.forEach((el) => {
          const busyTime: BusyTime = new BusyTime();
          const fechaReserva = new Date(el.fechaHoraReserva);
          busyTime.fecha = fechaReserva;
          busyTime.hour = fechaReserva.getHours();
          busyTime.min = fechaReserva.getMinutes();
          this.busyTimes.push(busyTime);
        });
      }
    }
  }

  selectTime(time: TimeOfCalendar): void {
    this.selectedCita.hour = time.hour;
    this.selectedCita.min = time.min;
    this.timeIsSelected.emit(this.selectedCita);
  }

  initCalendar(): void {
    this.loadDays = true;
    this.currentMonthCalendarDays = [];
    this.lastMonthCalendarDays = [];
    this.currentMonthFirstDate.setDate(1); // DOCS: Establecemos el primer día del mes
    const weekDay = this.currentMonthFirstDate.getDay(); // DOCS: Día de la semana del primer día del mes
    const lastDayOfMonth = new Date(
      this.currentMonthFirstDate.getFullYear(),
      this.currentMonthFirstDate.getMonth() + 1,
      0,
    ).getDate(); // DOCS: Último día del mes
    const lastMonthLastDay = new Date(
      this.currentMonthFirstDate.getFullYear(),
      this.currentMonthFirstDate.getMonth(),
      0,
    ).getDate();
    const months = this.ts.meses;
    this.calendarCurrentMonth = months[this.currentMonthFirstDate.getMonth()];
    this.calendarCurrentYear = this.currentMonthFirstDate.getFullYear();
    this.todayDateMonth = months[this.todayDate.getMonth()];
    this.todayDateYear = this.todayDate.getFullYear();

    for (let x = weekDay; x > 0; x--) {
      this.lastMonthCalendarDays.push({day: lastMonthLastDay - x + 1, color: '#94949485'});
    }

    for (let i = 1; i <= lastDayOfMonth; i++) {
      const day: DayItem = {day: i};
      if (
        i === this.todayDate.getDate() &&
        this.currentMonthFirstDate.getMonth() === this.todayDate.getMonth()
      ) {
        this.todayNumberDay = day;
      }
      this.currentMonthCalendarDays.push(day);
    }
    this.availableDays();
  }

  availableDays(): void {
    this.diasDisponibles=[];
    const lastDayOfMonth = new Date(
      this.currentMonthFirstDate.getFullYear(),
      this.currentMonthFirstDate.getMonth() + 1,
      0,
    ).getDate(); // DOCS: Último día del mes

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    for (let index = this.currentMonthFirstDate.getDate(); index <= lastDayOfMonth; index++) {
      const newDate = new Date(this.currentMonthFirstDate);
      newDate.setHours(0, 0, 0, 0);
      newDate.setDate(index);

      this.service.listaDisponibilidad.forEach((el: Disponibilidad): void => {
        if (Number(el.dia) === newDate.getDay() && newDate >= today) {
          this.currentMonthCalendarDays = this.currentMonthCalendarDays.map((o: DayItem) => {
            if (o.day === index) {
              o.color = '#1ad000';
              o.fontWeight = '600';
              o.animation = 'show 400ms';
            }
            return o;
          });
          this.diasDisponibles.push(newDate.getTime());
        }
      });
      this.loadDays = false;
    }
  }

  moveDate(para: string): void {
    if (para === 'prev') {
      this.currentMonthFirstDate.setMonth(this.currentMonthFirstDate.getMonth() - 1);
    } else {
      this.currentMonthFirstDate.setMonth(this.currentMonthFirstDate.getMonth() + 1);
    }
    this.initCalendar();
  }

  capturarHora(): void {
    const horaMin = this.horaSeleccionada.value;
    const separo = horaMin.split(':');
    const tiempo: TimeOfCalendar = new TimeOfCalendar();
    tiempo.hour = separo[0];
    tiempo.min = separo[1];
    this.selectTime(tiempo);
  }
}

import { Store } from '@ngrx/store';
import { Mbresult } from './models/auth.model';
import { ProfileSnippet, User } from './models/user';
import { PerfilService } from './services/perfil.service';
import { AppState } from './store.state';
import { selectTheme } from '@client-module/store/client.selectors';

export class Theme {
  user: User;
  profile: ProfileSnippet[];
  theme: string='default';
  activeProfileId: string;
  /**
   *
   */
  constructor(private perfServ: PerfilService,
              private store2: Store<AppState>) {
    // this.activeProfileId = localStorage.getItem('profile');
    // this.authSer.user$.subscribe(resp => {
    //   this.user = resp;

    //   this.profile = this.user.profiles.filter(p => p.profileId === this.activeProfileId);

    //   //console.log(this.profile);
    //   this.theme = this.profile[0].theme || 'default';
    // })
    this.store2.select(selectTheme).subscribe(resp=>{
      this.theme=resp;
    })
    //const userData = JSON.parse(localStorage.getItem('user-data'));

    // this.perfServ.leerTemaActivo(userData._id, userData.token).subscribe((resp) => {
    //   const mbResult: Mbresult = resp.data.mbclientePorPerfilActivo;

    //   if (mbResult.success && resp.data.mbclientePorPerfilActivo.mbCliente.listaMbPerfil[0]?.tema) {
    //     this.theme = resp.data.mbclientePorPerfilActivo.mbCliente.listaMbPerfil[0]?.tema;
    //   }
    // });
  }
}

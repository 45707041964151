import { createSelector } from '@ngrx/store';
import { ChatState, IChat } from '../models/chat.state';
import { selectChatState } from '@core/store.state';
import { Mbcliente } from '@models/auth.model';
import { MbHistorialChat } from '@models/mb-historial-chat.model';

export const selectChatStateSelector = createSelector(selectChatState, (state: ChatState) => state);
export const selectListChats = createSelector(
  selectChatStateSelector,
  (state: ChatState): Mbcliente[] => state?.listChats,
);

export const selectSelectedChat = createSelector(
  selectChatStateSelector,
  (state: ChatState): IChat => state?.selectedChat,
);

export const selectIsMessageSentSuccessfully = createSelector(
  selectChatStateSelector,
  (state: ChatState): boolean => state?.isMessageSentSuccessfully,
);
export const selectUnreadChats = createSelector(
  selectChatStateSelector,
  (state: ChatState): number => state?.unreadChats,
);

export const selectMessageCurrentChat = createSelector(
  selectSelectedChat,
  (state: IChat): MbHistorialChat[] => state?.messages,
);

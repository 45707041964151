import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { selectClientToken } from '@auth-module/store/auth.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class SocketsService {
  private socket!: Socket;
  private token = null;
  constructor(private store: Store) {
    this.store.select(selectClientToken).subscribe((token: string) => {
      this.token = token;
    });
  }

  /* DOCS: Importante, solo se debe conectar una vez en toda la aplicación. */
  connect() {
    this.socket = io(environment.urlSocket, {
      extraHeaders: {
        'x-api-key': environment.apiKeySocket,
        authorization: 'Bearer ' + this.token,
      },
    });

    this.socket.on('connect', () => {});

    this.socket.on('disconnect', () => {
      // console.log('Socket disconnected');
    });
    // return new Promise<void>((resolve, reject) => {
    //   this.socket = io.connect(environment.urlSocket, {
    //     extraHeaders: {
    //       'x-api-key': 'b9320d19-0dad-43cc-936a-e7a4eca323f9',
    //       'authorization': 'Bearer ' + token
    //     }
    //   });
    //   this.socket.on('connect', () => {
    //     console.log('Conexión establecida con éxito');
    //     resolve();
    //   });
    //   this.socket.on('connect_error', (error) => {
    //     console.error('Error al conectar:', error);
    //     reject(error);
    //   });
    // });
  }

  // Método para escuchar eventos del servidor
  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: any) => {
        subscriber.next(data);
      });
    });
  }

  // Método para enviar eventos al servidor
  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  // Método para desconectar el socket
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

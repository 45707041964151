import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs'

import { Alert } from '../models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  messaje: Subject<Alert>=new Subject<Alert>();


  constructor() {


 }


  sendMsj(type: string, msj: string) {
    this.messaje.next({type:type, msj:msj});
   }
}

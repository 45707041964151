import { SelectOption } from '@shared/models/select-option.model';
import { MolSelect } from '@shared/models/mol-select.model';
import { Estados } from '@models/paises.model';

export const tokenExpired = (token: string) => {
  const expiry = JSON.parse(atob(token?.split('.')[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
};

export const buildListForSelect = (
  arrayData: Array<any>,
  namePropertyId: string,
  namePropertyLabel?: string,
): string[] | SelectOption[] => {
  if (!namePropertyLabel) {
    return arrayData?.map((item): string => item[namePropertyId]) as string[];
  }
  return arrayData?.map((item): SelectOption => {
    return {
      value: item[namePropertyId],
      label: item[namePropertyLabel],
    };
  });
};

export const buildListForMolSelect = (
  arrayData: Array<any>,
  namePropertyId: string,
  namePropertyLabel: string,
  selectedValue: string,
): MolSelect[] => {
  return arrayData?.map((item): MolSelect => {
    return {
      value: namePropertyId ? item[namePropertyId] : item,
      text: namePropertyId ? item[namePropertyLabel] : item,
      selected: namePropertyId ? item[namePropertyId] === selectedValue : item === selectedValue,
      disable: false,
    };
  });
};

export const convertFileFromURLToBase64 = (url: string, blob: boolean = false): Promise<string> => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      // TODO: Retornar object URL
      if (blob) {
        resolve(window.URL.createObjectURL(new Blob([xhr.response])));
      } else {
        // TODO: Retornar base64
        const reader = new FileReader();
        reader.onload = (event) => {
          if (typeof event.target.result === 'string') {
            let result = event?.target?.result;
            const validateBase64 = result.split(',');
            if (validateBase64.length > 1) {
              result = validateBase64[1];
            }
            resolve(result);
          } else {
            resolve(null);
          }
        };
        reader.readAsDataURL(xhr.response);
      }
    };
    xhr.onerror = (e) => {
      resolve(null);
    };
    xhr.send();
  });
};
export function sortStates(states: Estados[]): Estados[] {
  return states.sort((a: Estados, b: Estados) => {
    const nameA = a.name;
    const nameB = b.name;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

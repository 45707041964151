<div *ngIf="!showSuccess" class="spinner">
  <app-spinner></app-spinner>
</div>


<!-- <div *ngIf="showSuccess">
<p>Reserva Aceptada</p>
</div> -->

<div class="contenedor">

   <!-- === RESERVA EXITOSA === -->

 <div class="reservaOk" *ngIf="showSuccess">
  <img class="reservaOk-Icon" src="../../../assets/service/reserve-ok-2.png">
  <p class="congratulations"> {{ts?.reservaOk.congratulations}} </p>
  <p class="reserve-success"> {{ts?.reservaOk.accepted}} </p>
</div>


</div>


<app-toast *ngIf="msj" [msj]="msj"></app-toast>


import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@helpers/shared.helpers';
import { Perfil } from '@models/perfil.model';
import { ServicioModel } from '@core/models/servicio.model';

const fileName = 'client';
export const setShowModalOfProfiles = createAction(
  buildStringNameForAction(fileName, 'Set show modal of profiles'),
  props<{ showModalOfProfiles: boolean }>(),
);
export const setShowModalOfServices = createAction(
  buildStringNameForAction(fileName, 'Set show modal of services'),
  props<{ showModalOfServices: boolean }>(),
);
export const setShowModalOfDeleteProfile = createAction(
  buildStringNameForAction(fileName, 'Set show modal of delete profile'),
  props<{ showModalOfDeleteProfile: boolean }>(),
);
export const setProfileIsLoading = createAction(
  buildStringNameForAction(fileName, 'Set profile is loading'),
  props<{ profileIsLoading: boolean }>(),
);
export const chooseActiveProfile = createAction(
  buildStringNameForAction(fileName, 'Choose active profile'),
  props<{ profile: Perfil }>(),
);
export const deleteProfileLoad = createAction(
  buildStringNameForAction(fileName, 'Delete profile load'),
  props<{ profile: Perfil }>(),
);
export const deleteProfileSuccess = createAction(
  buildStringNameForAction(fileName, 'Delete profile success'),
  props<{ profile: Perfil }>(),
);
export const updateProfileSuccess = createAction(
  buildStringNameForAction(fileName, 'Update profile success'),
  props<{ profile: Perfil }>(),
);
export const createProfileLoad = createAction(
  buildStringNameForAction(fileName, 'Create profile load'),
);
export const createProfileSuccess = createAction(
  buildStringNameForAction(fileName, 'Create profile success'),
);
export const checkIpbeforeCreateService = createAction(
  buildStringNameForAction(fileName, 'Check ip before create service'),
  props<{ service: ServicioModel }>(),
);
export const createService = createAction(
  buildStringNameForAction(fileName, 'Create service'),
  props<{ service: ServicioModel }>(),
);
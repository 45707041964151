import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store.state';
import { lastValueFrom, take } from 'rxjs';
import { AuthState } from '@auth-module/models/auth-state.model';
import { selectAuth } from '@auth-module/store/auth.selectors';
import { tokenExpired } from '@helpers/functions.helpers';
import { authLogout } from '@auth-module/store/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class Protect2Guard {
  async canMatch(store: Store<AppState>, router: Router): Promise<boolean | UrlTree> {
    const authState: AuthState = await lastValueFrom(store.pipe(select(selectAuth), take(1)));
    if (authState?.token && !tokenExpired(authState?.token)) {
      // DOCS: Si esta logueado, verifica si requiere pasar a mfa

      if (authState?.is2faActive && !authState?.is2faPassed) {
        // DOCS: Si necesita pasar a mfa pasa a /login
        return router.createUrlTree(['/login/mfa']);
      }
      return true;
    } else {
      store.dispatch(authLogout());
      return router.createUrlTree(['/login']);
    }
  }
}

export const protect2Guard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  return inject(Protect2Guard).canMatch(inject(Store<AppState>), inject(Router));
};

import { Estados } from '@models/paises.model';

export const browserRegexFinder = /firefox|opr|chrome(?!.*opr)|safari(?!.*opr)/i;

export enum Browsers {
  Chrome = 'Chrome',
  FireFox = 'Firefox',
  Safari = 'Safari',
  Opera = 'OPR',
  Other = 'Other',
}

export const buildStringNameForAction = (fileName: string, description: string): string => {
  return `[${fileName}] ${description}`;
};

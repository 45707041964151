import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@core/store.state';
import { catchError, from, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { SocketsService } from '@services/sockets.service';
import { map } from 'rxjs/operators';
import {
  selectUserData,
  selectUserDataWithActiveProfileId,
} from '@auth-module/store/auth.selectors';
import { emptyAction } from '@settings-module/store/settings.actions';
import {
  getChatDetailsLoad,
  getChatDetailsSuccess,
  getChatMessagesSuccess,
  getListOfChatLoad,
  getListOfChatSuccess,
  sendMessageFailed,
  sendMessageLoad,
  sendMessageSuccess,
  setMessage,
} from '@chat-module/store/chat.actions';
import { ChatService } from '@services/chat.service';
import { selectSelectedChat } from '@chat-module/store/chat.selectors';
import { EncryptionService } from '@services/app.encriptacion.service';

@Injectable()
export class ChatEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private socketsService: SocketsService,
    private chatService: ChatService,
    private encryptionService: EncryptionService,
  ) {}

  // DOCS: Obtención de la lista de chat que tiene el perfil en sesión
  getListOfChatLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListOfChatLoad),
      concatLatestFrom(() => [this.store.select(selectUserDataWithActiveProfileId)]),
      switchMap(([, userData]): Observable<Action> => {
        return this.chatService.getChatList(userData.id, userData.activeProfileId).pipe(
          map((resp) => {
            /* Algo falló*/
            if (!resp) {
              return emptyAction();
            }
            return getListOfChatSuccess({
              listChats: resp.mbhistorialchatConversacionPorClientePerfil,
            });
          }),
          catchError((error) => {
            return of(emptyAction());
          }),
        );
      }),
    ),
  );

  // DOCS: Obtienen los detalles del perfil con el que esta cheteando
  getChatDetailsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChatDetailsLoad),
      switchMap(({ profileId }): Observable<Action> => {
        // TODO: Revisar como devería compartarse este efecto si se requiere que se pueda chatear con perfiles y clientes
        // ya que si se le mande un id de un cliente devuelve null porque no existira un perfil con ese id

        return this.chatService.getProfileOfChat(profileId).pipe(
          map((resp) => {
            /* Algo falló*/
            if (!resp) {
              return emptyAction();
            }
            return getChatDetailsSuccess({
              chat: { ...resp.mbperfil.mbPerfil, messages: [] },
            });
          }),
          catchError((error) => {
            return of(emptyAction());
          }),
        );
      }),
    ),
  );

  // DOCS: Obtención de las mensajes del chat seleccionado
  getChatMessagesLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChatDetailsSuccess),
      concatLatestFrom(() => [this.store.select(selectUserDataWithActiveProfileId)]),
      switchMap(([{ chat }, userData]): Observable<Action> => {
        return this.chatService
          .getChatHistory(userData.id, chat.mbCliente._id, userData.activeProfileId, chat._id)
          .pipe(
            map((resp) => {
              /* Algo falló*/
              if (!resp) {
                return emptyAction();
              }
              return getChatMessagesSuccess({
                messages: resp.mbhistorialchatPorClientePerfil,
              });
            }),
            catchError((error) => {
              return of(emptyAction());
            }),
          );
      }),
    ),
  );

  // DOCS: Envio del mensaje
  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageLoad),
      concatLatestFrom(() => [
        this.store.select(selectUserDataWithActiveProfileId),
        this.store.select(selectSelectedChat),
      ]),
      switchMap(([{ message }, userData, selectedChat]): Observable<Action> => {
        const encryptedMessage = {
          mensaje: message,
          mbClienteEnvia: userData.id,
          mbPerfilEnvia: userData?.activeProfileId || null,
          mbClienteRecibe: selectedChat.mbCliente._id,
          mbPerfilRecibe: selectedChat?._id || null,
          fechaHoraRegistro: new Date().toISOString(),
        };
        return from(this.encryptionService.encrypt(JSON.stringify(encryptedMessage))).pipe(
          map((resp) => {
            this.socketsService.emit('chat', {
              data: resp,
            });
            this.store.dispatch(
              setMessage({
                message: {
                  mensaje: message,
                  mbClienteEnvia: {
                    _id: userData.id,
                    correo: null,
                    token: userData.token,
                    mbTransaccionSuscripcion: null,
                    llaveFactorAutenticacion: null,
                    tipoSuscripcion: null,
                    listaMbPerfil: null,
                  },
                  mbPerfilEnvia: userData
                    ? {
                        _id: userData.activeProfileId,
                        nombre: null,
                        imagen: null,
                      }
                    : null,
                  mbClienteRecibe: {
                    _id: selectedChat.mbCliente._id,
                    correo: null,
                    listaMbPerfil: null,
                    token: null,
                    mbTransaccionSuscripcion: null,
                    llaveFactorAutenticacion: null,
                    tipoSuscripcion: null,
                  },
                  mbPerfilRecibe: selectedChat?._id
                    ? {
                        _id: selectedChat?._id,
                        nombre: selectedChat.nombre,
                        imagen: selectedChat.imagen,
                      }
                    : null,
                  fechaHoraRegistro: new Date().toISOString(),
                  _id: Math.floor(Math.random() * 10).toString(),
                },
              }),
            );

            this.store.dispatch(sendMessageSuccess());
            return emptyAction();
          }),
          catchError((error) => {
            this.store.dispatch(sendMessageFailed());
            return of(emptyAction());
          }),
        );
      }),
    ),
  );

  /*// DOCS: Conecta el socket del chat para traer la lista de mensajes
  connectSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChatDetailsSuccess),
      withLatestFrom(this.store.select(selectUserData)),
      switchMap(([action, userData]): Observable<Action> => {
        return this.socketsService.listen('chat').pipe(
          map((message) => {
            console.log(message);
            this.store.dispatch(setMessageList({ listMessage: message }));
            return emptyAction();
          }),
          catchError((error) => {
            return of(emptyAction());
          }),
        );
      }),
    ),
  );*/
}

import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@helpers/shared.helpers';
import { Mbcliente } from '@models/auth.model';

const fileName = 'account';

export const uploadImageLoad = createAction(
  buildStringNameForAction(fileName, 'Upload image load'),
  props<{ client: Mbcliente; image: Blob; validateInfo: boolean }>(),
);
export const uploadImageError = createAction(
  buildStringNameForAction(fileName, 'Upload image error'),
);
export const updateClientLoad = createAction(
  buildStringNameForAction(fileName, 'Update client load'),
  props<{ client: Mbcliente; validateInfo: boolean }>(),
);
export const chargelegalRepresentativeLoad = createAction(
  buildStringNameForAction(fileName, 'Get legal representative load'),
);
export const chargeLegalRepresentativeSuccess = createAction(
  buildStringNameForAction(fileName, 'Get legal representative success'),
  props<{ legalRepresentative: Mbcliente }>(),
);
export const updateLegalRepresentative = createAction(
  buildStringNameForAction(fileName, 'update legal representative success'),
  props<{ legalRepresentative: Mbcliente }>(),
);
export const chargelegalRepresentativeError = createAction(
  buildStringNameForAction(fileName, 'Get legal representative error'),
);
export const updateClientSuccess = createAction(
  buildStringNameForAction(fileName, 'Update client success'),
  props<{ client: Mbcliente }>(),
);
export const updateClientError = createAction(
  buildStringNameForAction(fileName, 'Update client error'),
);
export const searchClientAccountsIsLoading = createAction(
  buildStringNameForAction(fileName, 'Search client accounts is loading'),
);
export const searchClientAccountsLoad = createAction(
  buildStringNameForAction(fileName, 'Search client accounts load'),
  props<{ searchTerm: string }>(),
);
export const searchClientAccountsSuccess = createAction(
  buildStringNameForAction(fileName, 'Search client accounts success'),
  props<{ clientsResults: Mbcliente[] }>(),
);
export const searchClientAccountsError = createAction(
  buildStringNameForAction(fileName, 'Search client accounts error'),
);
export const setClientToValidate = createAction(
  buildStringNameForAction(fileName, 'Select client to validate'),
  props<{ selectedClientToValidate: Mbcliente }>(),
);
export const removeClientToValidate = createAction(
  buildStringNameForAction(fileName, 'Remove client to validate'),
);
export const setClientToValidateIsLogged = createAction(
  buildStringNameForAction(fileName, 'Select client to validate is logged'),
);
export const setShowModalForCreateAccount = createAction(
  buildStringNameForAction(fileName, 'Set show modal for create account'),
  props<{ showModalForCreateAccount: boolean }>(),
);
export const updateIndividualClientLoad = createAction(
  buildStringNameForAction(fileName, 'Update individual client load'),
  props<{ client: Mbcliente }>(),
);
export const updateIndividualClientSuccess = createAction(
  buildStringNameForAction(fileName, 'Update individual client success'),
  props<{ client: Mbcliente }>(),
);
export const createIndividualClientLoad = createAction(
  buildStringNameForAction(fileName, 'Create individual client load'),
  props<{ client: Mbcliente }>(),
);
export const resendCodeForVerifyAccountLoad = createAction(
  buildStringNameForAction(fileName, 'Resend code for verify account load'),
);
export const resendCodeForVerifyAccountSuccess = createAction(
  buildStringNameForAction(fileName, 'Resend code for verify account success'),
);
export const resendCodeForVerifyAccountError = createAction(
  buildStringNameForAction(fileName, 'Resend code for verify account error'),
);
export const checkCodeForVerifyAccountLoad = createAction(
  buildStringNameForAction(fileName, 'Check code for verify account load'),
  props<{ code: string }>(),
);
export const checkCodeForVerifyAccountSuccess = createAction(
  buildStringNameForAction(fileName, 'Check code for verify account success'),
);
export const checkCodeForVerifyAccountError = createAction(
  buildStringNameForAction(fileName, 'Check code for verify account error'),
);

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuState = new BehaviorSubject<boolean>(true);

  getMenuState(): Observable<boolean> {
    return this.menuState.asObservable();
  }

  setMenuState(state: boolean): void {
    this.menuState.next(state);
  }
}

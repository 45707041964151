import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { Perfil } from '@models/perfil.model';
import { Mbcliente } from '@models/auth.model';
import { map } from 'rxjs/operators';

export interface AppStore {
  activeProfile?: Perfil;
  clientData?: Mbcliente;
  userData?: Mbcliente;
  profileId?: string;
  profileList?: Perfil[];
  type?: string;
  headerData?: HeaderData;
  sidebarData?: SidebarData;
  isAuthenticated?: boolean;
  mfaFromLogin?: boolean;
  initial?: boolean;
}

export interface HeaderData {
  showHeader?: boolean;
  title?: string;
  theme?: string;
  wco?: boolean;
  showShare?: boolean;
}

export interface SidebarData {
  showSidebar?: boolean;
  showChangeActiveProfile?: boolean;
}

const initialStore: AppStore = {
  activeProfile: {} as Perfil,
  clientData: {} as Mbcliente,
  userData: {} as Mbcliente,
  profileId: '',
  profileList: [],
  type: '',
  headerData: {
    showHeader: true,
    title: '',
    theme: 'default',
    wco: false,
    showShare: false,
  },
  sidebarData: {
    showSidebar: false,
    showChangeActiveProfile: false,
  },
};
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private store = new BehaviorSubject<AppStore>(initialStore);
  constructor() {}

  setStore(store: AppStore): void {
    this.store?.next(store);
  }

  async setMfaFromLogin(store: boolean): Promise<void> {
    const store2: AppStore = await firstValueFrom(this.getStore);
    this.store.next({
      ...store2,
      mfaFromLogin: store,
    });
  }

  get getStore(): Observable<AppStore> {
    return this.store.asObservable();
  }
  get getMfaFromLogin(): Observable<boolean> {
    return this.store.asObservable().pipe(map((store: AppStore) => store.mfaFromLogin));
  }
}

import { SettingsState } from '../models/settings-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
  SET_APPLICATION_CONFIGURATION,
  SET_BROWSER,
  SET_PUBLIC_IP,
  setAppInitializationComplete,
  setNavigatorLanguage,
} from './settings.actions';

export const initialState: SettingsState = {
  language: 'en',
  initializationComplete: false,
  appSettings: {
    signalAppId: null,
    verifyPayment: null,
    secretKey: null,
  },
  currentBrowser: null,
  publicIp: null,
};

const reducer = createReducer(
  { ...initialState },
  on(
    SET_APPLICATION_CONFIGURATION,
    (state: SettingsState, { appSettings }): SettingsState => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        ...appSettings,
      },
    }),
  ),
  on(SET_PUBLIC_IP, (state, { publicIp }) => ({
    ...state,
    publicIp,
  })),
  on(
    setAppInitializationComplete,
    (state: SettingsState): SettingsState => ({
      ...state,
      initializationComplete: true,
    }),
  ),
  on(SET_BROWSER, (state, { currentBrowser }) => ({
    ...state,
    currentBrowser,
  })),

  on(setNavigatorLanguage, (state: SettingsState, { language }) => ({
    ...state,
    language,
  })),
);

export function settingsReducer(state: SettingsState | undefined, action: Action) {
  return reducer(state, action);
}

import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { lastValueFrom, take } from 'rxjs';
import { tokenExpired } from '@helpers/functions.helpers';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store.state';
import { AuthState } from '@auth-module/models/auth-state.model';
import { selectAuth } from '@auth-module/store/auth.selectors';
import { selectShowInitialSteps } from '@client-module/store/client.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  async canMatch(store: Store<AppState>, router: Router): Promise<boolean | UrlTree> {
    const authState: AuthState = await lastValueFrom(store.pipe(select(selectAuth), take(1)));

    const showInitialSteps: boolean = await lastValueFrom(
      store.pipe(select(selectShowInitialSteps), take(1)),
    );
    if (authState?.token && !tokenExpired(authState?.token)) {
      // DOCS: Si esta logueado, verifica si requiere pasar a mfa

      // DOCS: Cambiar a store ngrx
      if ((authState?.is2faActive && !authState?.is2faPassed) || showInitialSteps) {
        // DOCS: Si necesita pasar a mfa pasa a /login
        return true;
      }
      // DOCS: Si esta logueado y no necesita pasar a mfa navega a /profile
      return router.createUrlTree(['/profile']);
    }
    // DOCS: Si no esta logueado, pasa a /login
    return true;
  }
}

export const loginGuard: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  return inject(LoginGuard).canMatch(inject(Store<AppState>), inject(Router));
};

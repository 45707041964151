<div *ngIf="isLoading" class="spinner">
  <app-spinner></app-spinner>
</div>

<div *ngIf="!isLoading" class=" booking w-screen">
  
  <div
    *ngIf="
      showForm && !isBooked && !isError && !paymentCancelled && !paymentError
    "
    class="md:w-2/4 md:mx-auto mt-16"
  >

    <p class="font-normal text-center text-gray-800 text-lg px-4">
      {{ts.bookingForm.reserve}}
    </p>
    <br />

    <form class="p-1 w-11/12 mx-auto" [formGroup]="bookingForm" (ngSubmit)="bookAppointment()">
      <div >
        
        <div class="relative w-full">
          <select
            class="block appearance-none w-full bg-white border border-purple-700 text-gray-700 py-2 px-4 pr-8 rounded-xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="services"
            formControlName="service"
          >
            <option
              *ngFor="let s of profile?.services; let idx = index"
              [value]="idx"
              (click)="setDateSlots(idx)"
            >
              {{ s.name }} <span *ngIf="s.rate > 0">(${{ s.rate }})</span>
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
      <hr class="mt-2 mb-2" />

      <!-- service notes -->
      <div class="pt-2 px-2">
        <h4 class="font-semibold text-lg text-center text-gray-700 my-2">
          {{ts.bookingForm.infoService}}
        </h4>
        <p *ngIf="serviceDurationDec < 60">
          <span class="font-semibold">{{ts.bookingForm.duration}}</span>
          {{ serviceDuration  }} {{ts.bookingForm.minutes}}
        </p>
<p *ngIf="serviceDurationDec > 60 && serviceDurationDec < 1440">
  <span class="font-semibold">{{ts.bookingForm.duration}}</span>
  {{ serviceDurationDec / 60 | number: '1.0-1'}} {{ts.bookingForm.hour}}
</p>
<p *ngIf="serviceDurationDec > 1440 && serviceDurationDec < 43200">
  <span class="font-semibold">{{ts.bookingForm.duration}}</span>
  {{serviceDurationDec / 1440| number: '1.0-1' }} {{ts.bookingForm.day}}
</p>
<p *ngIf="serviceDurationDec > 43200">
  <span class="font-semibold">{{ts.bookingForm.duration}}</span>
  {{serviceDurationDec / 43200 | number: '1.0-1'}} {{ts.bookingForm.month}}
</p>
        <br />
        <p *ngIf="serviceNote">
          <span class="font-semibold">{{ts.bookingForm.details}}</span> {{ serviceNote }}
        </p>
        <br />
      </div>

      <!-- calendar -->
      <div class="pb-2 px-2">
        <label class="font-normal text-gray-800" for="appointmentDate"
          >{{ts.bookingForm.selectDate}}</label
        >
        <div class="relative flex flex-row" (click)="dp.toggleCalendar()">
          <input
            class="appearance-none placeholder-gray-500 placeholder-opacity-50 font-light text-gray-700 bg-white border border-gray-700 b-white rounded-xl rounded-r-none w-full py-2 px-3 focus:outline-none focus:border-purple-800"
            placeholder="DD.MM.AAAA"
            angular-mydatepicker
            formControlName="appointmentDate"
            [options]="datePickerOptions"
            #dp="angular-mydatepicker"
            locale="es"
            (dateChanged)="onDateChanged($event)"
            disabled
          />
          <button
            class="right-0 bg-gray-700 hover:bg-purple-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-3 rounded-r-xl rounded-l-none text-lg"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="white"
            >
              <path
                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"
              />
            </svg>
          </button>
        </div>
        <p
          *ngIf="hasValidationErrors('appointmentDate')"
          class="text-red-500 text-xs text-left"
        >
        {{ts.errorMessages.dateRequired}}
        </p>
      </div>

      <div class="relative w-full p-2">
        <label class="font-normal text-gray-800" for="time"
          >{{ts.bookingForm.selectHour}}</label
        >
        <select
          *ngIf="slots?.length > 0"
          class="block appearance-none w-2/4 bg-white border border-purple-700 text-gray-700 py-2 px-4 pr-8 rounded-xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="time"
          formControlName="time"
        >
          <option *ngFor="let slot of slots; let idx = index" [value]="idx">
            {{ slot }}
          </option>
        </select>
        <p *ngIf="slots?.length < 1" class="text-xs text-left">
          {{ts.bookingForm.dateAvailable}}
        </p>
        <p
          *ngIf="hasValidationErrors('time')"
          class="text-red-500 text-xs text-left"
        >
        {{ts.errorMessages.hourRequired}}
        </p>
        <br />
      </div>

      <hr class="my-2" />

      <p class="font-semibold text-lg text-center text-purple-800 my-4">
        {{ts.bookingForm.informationPersonal}}
      </p>

      <!-- name -->
      <div class="p-2">
        <label class="font-normal text-gray-800" for="name"
          >{{ts.bookingForm.name}}</label
        >
        <input
          class="appearance-none placeholder-gray-500 placeholder-opacity-50 font-light text-gray-700 border border-gray-700 rounded-xl w-full py-2 px-3 focus:outline-none focus:border-purple-800"
          id="name"
          formControlName="name"
          type="text"
          placeholder="Nombre completo"
          [class.border-red-500]="hasValidationErrors('name')"
          required
        />
        <p
          *ngIf="hasValidationErrors('name')"
          class="text-red-500 text-xs text-left"
        >
        {{ts.errorMessages.nameRequired}}
        </p>
      </div>
      <!-- email -->
      <div class="p-2">
        <label class="font-normal text-gray-800" for="fullname"
          >{{ts.bookingForm.email}}</label
        >
        <input
          class="appearance-none placeholder-gray-500 placeholder-opacity-50 font-light text-gray-700 border border-gray-700 rounded-xl w-full py-2 px-3 focus:outline-none focus:border-purple-800"
          id="email"
          formControlName="email"
          type="email"
          placeholder="Correo electrónico"
          [class.border-red-500]="hasValidationErrors('email')"
          required
        />
        <p
          *ngIf="hasValidationErrors('email')"
          class="text-red-500 text-xs text-left"
        >
        {{ts.errorMessages.emailRequired}}
        </p>
      </div>
      <!-- phone -->
      <div class="p-2">
        <label class="font-normal text-gray-800" for="fullname"
          >{{ts.bookingForm.phone}}</label
        >
        <input
          class="appearance-none placeholder-gray-500 placeholder-opacity-50 font-light text-gray-700 border border-gray-700 rounded-xl w-full py-2 px-3 focus:outline-none focus:border-purple-800"
          id="phone"
          formControlName="phone"
          type="text"
          placeholder="Número de teléfono"
          [class.border-red-500]="hasValidationErrors('phone')"
          required
        />
        <p
          *ngIf="hasValidationErrors('phone')"
          class="text-red-500 text-xs text-center"
        >
        {{ts.errorMessages.phoneRequired}}
        </p>
      </div>
      <!-- phone -->
      <div class="p-2">
        <label class="font-normal text-gray-800" for="fullname"
          >{{ts.bookingForm.address}}<span class="text-sm italic">{{ts.bookingForm.optional}}</span></label
        >
        <input
          class="appearance-none placeholder-gray-500 placeholder-opacity-50 font-light text-gray-700 border border-gray-700 rounded-xl w-full py-2 px-3 focus:outline-none focus:border-purple-800"
          id="address"
          formControlName="address"
          type="text"
          placeholder="Dirección"
          [class.border-red-500]="hasValidationErrors('address')"
        />
        <!-- <p
          *ngIf="hasValidationErrors('address')"
          class="text-red-500 text-xs text-center"
        >
          Ingrese un número de teléfono válido.
        </p> -->
      </div>
      <!-- notes -->
      <div class="p-2">
        <label class="font-normal text-gray-800" for="fullname">{{ts.bookingForm.notes}}</label>
        <textarea
          class="appearance-none placeholder-gray-500 placeholder-opacity-50 font-light text-gray-700 border border-gray-700 rounded-xl w-full py-2 px-3 focus:outline-none focus:border-purple-800"
          id="notes"
          formControlName="notes"
          type="text"
          placeholder="Agrega una nota para {{ profile?.fullname }}"
        ></textarea>
      </div>
      <!-- Pay? -->
      <div class="p-2" *ngIf="serviceFee">
        <input
          type="checkbox"
          id="isPaying"
          class="border-gray-700 rounded-xl focus:outline-none focus:border-purple-800"
          name="isPaying"
          value="true"
          formControlName="isPaying"
        />
        <label class="pl-2" for="isPaying">{{ts.bookingForm.paySevice}}</label
        ><br />
      </div>

      <div class="w-full px-4 py-2 mb-32">
        <p *ngIf="bookingError" class="text-red-500 text-center mb-2">
          {{ts.errorMessages.bookingError}}
        </p>
        <button
          class="block mx-auto shadow focus:shadow-outline focus:outline-none text-white font-semibold py-2 px-8 rounded-lg text-lg"
          type="submit"
          [ngClass]="{
            'bg-gray-500 text-white hover:bg-gray-600': !isFormInvalid,
            'bg-gray-400 text-white': isFormInvalid
          }"
        >
        {{ts.bookingForm.reservar}}
        </button>
      </div>
    </form>
  </div>



 


   <!-- ======= RESERVA REGISTRADA =========== -->


   <div class="reserva-registrada" *ngIf="isBooked">
    <div class="title-reserva">{{ts.reservaRegistrada.title}}</div>

    <div class="photo-and-name">
      <div class="photo">
        <img src="{{ profile?.photo || '../../assets/default-avatar.png' }}">
      </div>

      <div class="name">
        <p> {{ profile?.fullname }}</p>
      </div>

    <!--   <p *ngIf="profile.email">
        <a href="{{ 'mailto:' + profile?.email }}">{{
          profile?.email
        }}</a>
      </p> -->
    <!--   <p *ngIf="profile.phone" >
        <a href="{{ 'tel:' + profile?.phone }}">{{ profile?.phone }}</a>
      </p> -->
    </div>

   <!--  <div class="item-detalles" *ngIf="profile.phone">
      <p>
        <strong>{{ts.ts.reservaRegistrada.phone}}</strong>
        <a href="{{ 'tel:' + profile?.phone }}">{{ profile?.phone }}</a>
      </p>
    </div> -->

     <!--  <div class="item-detalles" *ngIf="profile.email">
      <p>
        <strong>{{ts.ts.reservaRegistrada.email}}</strong>
        <a href="{{ 'mailto:' + profile?.email}}">{{ profile?.email }}</a>
      </p>
    </div> -->

    <div class="item-detalles">
      <p>
        <strong>{{ts.reservaRegistrada.email}}</strong>
        <a>person_prueba@correo.com</a>
      </p>
    </div>

    <div class="item-detalles">
      <p>
        <strong>{{ts.reservaRegistrada.phone}}</strong>
        <a>+ 305 -02585158</a>
      </p>
    </div>



    <div class="item-detalles">
      <p> <strong>{{ts.reservaRegistrada.service}}</strong>
        {{ service }}
      </p>
    </div>

    <div class="item-detalles">
      <p> <strong>{{ts.reservaRegistrada.date}}</strong>
        {{ date }}
      </p>
    </div>


    <div class="item-detalles">
      <p> <strong>{{ts.reservaRegistrada.details}}</strong>
        {{ serviceNote }}
      </p>
    </div>


    <div class="confirmacion">
      <p>
        {{ts.infoMessages.confirmEmail}}
        <strong> {{ profile?.fullname }}</strong> {{ts.infoMessages.confirmEmail2}}
      </p>
    </div>

    <div class="info-reserva">
      <p>
        {{ts.infoMessages.changes1}}
      {{ profile?.fullname }} {{ts.infoMessages.changes2}}
      </p>
    </div>


  </div>





  <!-- Payment Cancelled Notice -->
  <div
    class="rounded border border-purple-700 px-8 pb-16 mt-4 mx-4 md:container mx:auto"
    *ngIf="paymentCancelled"
  >
    <h2 class="text-purple-700 text-xl font-bold text-center mt-6 p-4">
      {{ts.infoMessages.payCanceled}}
    </h2>
    <br />
    <button
      (click)="returnToProfile()"
      class="bg-purple-700 hover:bg-purple-600 block mx-auto shadow focus:shadow-outline focus:outline-none text-white font-bold py-2 px-8 rounded text-lg"
    >
      {{ts.bookingForm.back}}
    </button>
    <br />
  </div>

  <div
    class="rounded border border-purple-700 px-8 pb-16 mt-4 mx-4 md:container mx:auto"
    *ngIf="paymentError"
  >
    <h2 class="text-purple-700 text-xl font-bold text-center mt-6 p-4">
      {{ts.errorMessages.bookingError2}}
    </h2>
    <p class="text-lg text-gray-700">{{ts.infoMessages.tryLater}}</p>
    <br />
    <button
      (click)="returnToProfile()"
      class="bg-purple-700 hover:bg-purple-600 block mx-auto shadow focus:shadow-outline focus:outline-none text-white font-bold py-2 px-8 rounded text-lg"
    >
    {{ts.bookingForm.back}}
    </button>
    <br />
  </div>

  <div
    class="rounded border border-purple-700 px-8 pb-16 mt-4 mx-4 md:container mx:auto"
    *ngIf="isError"
  >
    <h2 class="text-purple-700 text-xl font-bold text-center mt-6 p-4">
      {{ts.errorMessages.bookingError2}}
    </h2>
    <p class="text-lg text-gray-700">{{ts.infoMessages.tryLater}}</p>
    <br />
    <button
      (click)="returnToProfile()"
      class="bg-purple-700 hover:bg-purple-600 block mx-auto shadow focus:shadow-outline focus:outline-none text-white font-bold py-2 px-8 rounded text-lg"
    >
    {{ts.bookingForm.back}}
    </button>
    <br />
  </div>

 
</div>

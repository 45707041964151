import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  activeProfileId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  signIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  singIn: boolean = false;
  constructor(private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router) {

    this.authState();
  }


  authState() {

    this.auth.user.subscribe((user) => {
      if (user) {
        this.signIn$.next(true)
        this.singIn = true;
        if (!this.userId) {
          this.userId = new BehaviorSubject<string>(user.uid);

        }
        else {
          this.userId.next(user.uid);
        }



        var activeProfileId = localStorage.getItem('profile');
        if (!activeProfileId) {
          this.router.navigate(['/cuenta']);
        }
        if (!this.activeProfileId) {
          this.activeProfileId = new BehaviorSubject<string>(activeProfileId);
        } else {

          this.activeProfileId.next(activeProfileId);
        }




      } else {
        this.signIn$.next(false)
        this.router.navigate(['/login']);
      }
    });
  }
}

<div *ngIf="isLoading" class="spinner">
  <app-spinner></app-spinner>
</div>


<div class="contenedor" *ngIf="!isLoading && !scanError && !otherError && !profileNotActive">
  <div class="logo">
  </div>
</div>

<div class="scanError" *ngIf="scanError">
  <div class="imagen">
    <img src="./../../assets/errors/notifications.png" alt="">
  </div>
  <div class="text">
    <p>{{ts?.error.linea1}}</p>
    <p> {{ts?.error.linea2}} <strong [routerLink]="['/']">app.januus.com</strong> </p>
    <p>{{ts?.error.linea3}}</p>

    <p>{{ts?.error.linea4}}<strong [routerLink]="['/']"> {{ts?.error.ingrese}}</strong> </p>
    <p>{{ts?.error.linea5}}</p>

  </div>

</div>
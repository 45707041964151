import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(public http: HttpClient) {


  }

  //url: String = "http://localhost:5001/contactame-app/us-central1/apiemail/send";
  url: string ='https://us-central1-contactame-app.cloudfunctions.net/apiemail/send';

  sendEmail(email:string): Observable<any> {


    return this.http.post<any>(`${this.url}/welcome`, {email:email});

  }

}

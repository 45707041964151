import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Mbresult } from 'src/app/core/models/auth.model';
import { ReservaService } from 'src/app/core/services/reserva.service';
import { LoadjsonService } from 'src/app/core/services/loadjson.service';
import { Alert } from '../../core/models/alert.model';
import { AlertsService } from '../../core/services/alerts.service';
import { ReservaResponse } from 'src/app/core/models/reserva.model';

@Component({
  selector: 'app-reserve-cancel',
  templateUrl: './reserve-cancel.component.html',
  styleUrls: ['./reserve-cancel.component.css'],
})
export class ReserveCancelComponent implements OnInit {
  reservaId: string;
  token: string;
  showSuccess: boolean;
  showSpinner:boolean;
  ts: any;
  msj: Alert;
  showCancelMessage: boolean;
  reserva: ReservaResponse;
  showReserve:boolean;
  paraCliente:boolean;
  constructor(
    private route: ActivatedRoute,
    private reServ: ReservaService,
    private translate: LoadjsonService,
    private alertServ: AlertsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.reservaId = params.id;
      this.token = params.token;
      this.paraCliente= params.paraCliente;
    });

    //language
    this.translate.loadCitas().subscribe((resp) => {
      this.ts = resp;
    });

    //alert services
    this.alertServ.messaje.subscribe((resp) => {
      this.msj = resp;
    });
  }

  ngOnInit(): void {

    this.leerReserva(this.reservaId, this.token);

  }

  cancelarReserva(idReserva: string, token: string) {
    this.reServ.rechazarReserva(idReserva, token, !this.paraCliente).subscribe((resp) => {
      const mbResult: Mbresult = resp.data.rechazarMbreserva;

      this.showSpinner=false;

      if (mbResult.success) {

        this.showCancelMessage=false;
        this.showSuccess = true;

      } else {
        //enviar mensaje de error

        this.alertServ.sendMsj('danger', this.ts.reserveCancel.alerts.cancelReserveError);

      }
    });
  }

  confirm() {
    this.showSpinner=true;
    this.cancelarReserva(this.reservaId, this.token);
  }
  cancel(){
    this.showCancelMessage=!this.showCancelMessage;
    this.showReserve=true;
  }
  leerReserva(id: string, token: string) {
    //const userData = JSON.parse(localStorage.getItem('user-data'));
    this.reServ.leerReserva(id, token).subscribe(resp => {
      console.log('reserva ', resp);
      const mbResult: Mbresult = resp.data.mbreserva;
      if (mbResult.success) {
        this.reserva = resp.data.mbreserva.mbReserva;

        const fechaDeRegistro= new Date(this.reserva.fechaHoraRegistro);
        const fechaReserva= new Date(this.reserva.fechaHoraReserva)
        const fechaHoy=new Date();

        //test
        //const fechaDeRegistro = new Date('10/05/2023');
        //const fechaReserva = new Date('10/12/2023')
        //const fechaHoy = new Date('10/09/2023');

        //console.log('fechaRegistro ' + fechaDeRegistro + ' fecha reserva ' + fechaReserva + ' hoy ' + fechaHoy)

        const tiempoReserva = fechaReserva.getTime() - fechaDeRegistro.getTime();
        const tiempoHastaHoy = fechaHoy.getTime() - fechaDeRegistro.getTime();
        const tiempo50PorCiento = tiempoReserva / 2;

        //console.log('tiempoReserva ' +  tiempo50PorCiento + 'tiempoHastaHoy ' + tiempoHastaHoy);

        if (tiempoHastaHoy > tiempo50PorCiento) {
          this.showCancelMessage = true;

        } else {
          this.showCancelMessage = false;
          this.showSpinner=true;
          this.cancelarReserva(this.reservaId, token);
        }




      }
    })
  }
}

import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { format, formatDistance } from 'date-fns';
import { es } from 'date-fns/locale';
import { Appointment } from '../core/models/appointments';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LoadjsonService } from 'src/app/core/services/loadjson.service';
import { Theme } from 'src/app/core/theme';
import { AuthService } from 'src/app/core/services/auth.service';
import { PerfilService } from '../core/services/perfil.service';
import { ReservaService } from '../core/services/reserva.service';
import { Mbresult, UserData } from '../core/models/auth.model';
import { ReservaResponse } from '../core/models/reserva.model';
import { Store, select } from '@ngrx/store';
import { AppState } from '../core/store.state';
import { selectUserData } from '@auth-module/store/auth.selectors';
import { lastValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
/**
 * Supported comment
 */
export class AppointmentsComponent extends Theme implements OnInit {

  appt: Appointment;
  apptId: string;
  reserva: ReservaResponse;
  isLoading = true;
  isError = false;
  status = '';

  activeProfileId;
  userId;

  profileId: string;

  @ViewChild('deleteConfirmationModal') deleteConfirmationModal: ElementRef;
  showDeleteConfirmation = false;
  toDelete: Appointment = null;

  ts: any;
  userData: UserData;
  
  constructor(private router: Router, private route: ActivatedRoute, 
    private ngZone: NgZone,
    public translate: LoadjsonService,
    private location: Location,
    private perServ: PerfilService,
    authSer: AuthService,
    private reServ: ReservaService,
    private store:Store<AppState>) {
    super(perServ, store);

    this.translate.loadCitas().subscribe((resp) => {
      this.ts = resp;
    });
    
 
  }

  async ngOnInit(): Promise<void> {
    this.apptId = this.route.snapshot.paramMap.get('id');
    let appointment = JSON.parse(localStorage.getItem('appointment'));
    this.activeProfileId = localStorage.getItem('profile');
    this.userData=await lastValueFrom(this.store.pipe(select(selectUserData), take(1)));

    this.leerReserva();
  }

  formatDate(epoch: number): string {
    return format(epoch, "yyyy-MM-dd', 'HH:mm a");
  }

  timeFrom(date: number): string {
    return formatDistance(date, Date.now(), { locale: es });
  }

  goTo() {
    this.location.back();
  }

  private loadAppointment() {
    // this.firestore
    //   .collection('appointments')
    //   .doc(this.apptId)
    //   .get()
    //   .subscribe((val) => {
    //     if (val.exists) {
    //       this.appt = val.data() as Appointment;
    //       this.ngZone.run(() => {
    //         if (this.appt.isPaid) {
    //           this.status = 'Pago Recibido';
    //         } else if (this.appt.status == 'Confirmed' && !this.appt.isPaid) {
    //           this.status = 'Confirmed';
    //         } else if (this.appt.status == 'Pending' && this.appt.service.paymentRequired) {
    //           this.status = 'Pending';
    //         }
    //         this.isLoading = false;
    //       });
    //     } else {
    //       this.ngZone.run(() => {
    //         this.isError = true;
    //         this.isLoading = false;
    //       });
    //     }
    //   }, (err) => {
    //     console.log(err);
    //     this.isLoading = false;
    //   });
  }

  closeDeleteConfirmationModal(evt: Event) {
    if (
      this.showDeleteConfirmation &&
      !this.deleteConfirmationModal.nativeElement.contains(evt.target)
    ) {
      this.showDeleteConfirmation = false;
    }
  }

  setAppointmentToDel(appt: Appointment) {
    this.toDelete = appt;
    this.showDeleteConfirmation = true;
  }

  // deleteAppointment(): void {
  //   this.isLoading = true;
  //   if (this.toDelete) {
  //     this.firestore
  //       .collection('appointments')
  //       .doc(this.toDelete.id)
  //       .delete()
  //       .then(
  //         () => {
  //           this.isLoading = false;
  //           this.showDeleteConfirmation = false;
  //           this.router.navigate(['/solicitudes']);
  //         },
  //         (err) => {
  //           console.log('could not delete appointment:', err);
  //           this.isLoading = false;
  //           this.showDeleteConfirmation = false;
  //         }
  //       );
  //   }
  // }

  cancelDelete(): void {
    this.toDelete = null;
    this.showDeleteConfirmation = false;
  }
  leerReserva(): void {

    //const userData = JSON.parse(localStorage.getItem('user-data'));
    this.reServ.leerReserva(this.apptId, this.userData.token).subscribe(resp => {

      const mbResult: Mbresult = resp.data.mbreserva

      if (mbResult.success) {

        this.reserva = resp.data.mbreserva.mbReserva;
        //this.reserva.fechaHoraReserva
        this.isLoading = false;
        console.log('ver id',this.reserva.mbPerfilProfesional._id);
        console.log('id',this.activeProfileId);
      } else {

        //mensaje de error

      }

    })


  }

  trDate(date: any): number {

    const newDate: Date = new Date(date);
    return newDate.getMilliseconds()

  }
}

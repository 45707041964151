import { Injectable } from '@angular/core';
import { StoreService } from './store.service';
import { AppStoreKeys } from '@helpers/core.helpers';
import { LocalStorageItem } from '@models/local-storage-item.model';
import { update } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  showTutorial = new BehaviorSubject(this.getItem('showTutorial'));
  constructor(private storeService: StoreService) {}

  static loadInitialState() {
    return Object.keys(sessionStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(AppStoreKeys.app)) {
        const stateKeys = storageKey
          .replace(AppStoreKeys.app, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1),
              )
              .join(''),
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(sessionStorage.getItem(storageKey) || '');
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  getItem(key: string): any {
    return JSON.parse(localStorage.getItem(`${AppStoreKeys.app}${key}`));
  }

  logOut(): void {
    localStorage.removeItem('user-data');
    localStorage.removeItem('client-data');
    localStorage.removeItem('active-profile');
    localStorage.removeItem('profile');
    localStorage.removeItem('type');
    this.removeItem(AppStoreKeys.auth);
    this.storeService.setStore({
      activeProfile: null,
      clientData: null,
      userData: null,
      profileId: null,
      type: null,
      headerData: null,
    });
  }

  setItem(payload: LocalStorageItem | LocalStorageItem[]): void {
    if (Array.isArray(payload)) {
      payload.forEach((item: LocalStorageItem) => {
        localStorage.setItem(`${AppStoreKeys.app}${item.key}`, JSON.stringify(item.value));
      });
    } else {
      localStorage.setItem(`${AppStoreKeys.app}${payload.key}`, JSON.stringify(payload.value));
    }
  }

  updateItem(key: string, value: any, path: string): void {
    const packedData: any = this.getItem(key);
    if (packedData) {
      update(packedData, path, () => {
        return value;
      });
      this.setItem({ key, value: packedData });
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(`${AppStoreKeys.app}${key}`);
  }

  setShowTutorial(value: boolean) {
    this.setItem({ key: 'showTutorial', value });
    this.showTutorial.next(value); // this will make sure to tell every subscriber about the change.
  }

  getShowTutorial() {
    return this.showTutorial.asObservable();
  }
}

enum AppViewTypes {
  Small = 'small',
  Standard = 'standard',
  Big = 'big',
}

export default AppViewTypes;

export enum AppStoreKeys {
  app = 'januus-',
  auth = 'auth',
  router = 'router',
  settings = 'settings',
  client = 'client',
  profile = 'profile',
  is2faPassed = 'is2faPassed',
  chat = 'chat',
}

export enum AccountTypes {
  INDIVIDUAL = '65e667270a1997a240b93dda',
  ENTERPRISE = '65e667610a1997a240b93ddb',
  EDUCATIONAL = '65e667970a1997a240b93ddc',
}
export enum AccountTypesString {
  INDIVIDUAL = 'individual',
  ENTERPRISE = 'enterprise',
  EDUCATIONAL = 'educational',
  BOTH = 'both',
}

export const countryList: string[] = ['United States', 'Argentina', 'Mexico', 'Ecuador', 'Colombia'];

import { Component, OnInit, NgZone, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router, ActivatedRoute } from '@angular/router';
import { addMinutes, compareAsc, format } from 'date-fns';
import { Appointment } from '../../core/models/appointments';
import { Location } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../core/services/profile.service';
import { Profile } from '../../core/models/profile';
import { Messaging } from '../../core/messaging';
import { NotificationService } from '../../core/services/notification.service';
import { LoadjsonService } from 'src/app/core/services/loadjson.service';
import { CitasService } from 'src/app/core/services/citas.service';
import { AlertsService } from 'src/app/core/services/alerts.service';



declare var Stripe;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css'],
})
export class BookingComponent extends Messaging implements OnInit, OnDestroy {
@Input() profileInput:string;
@Input() userPhoto:string;
  @Input() servicePhoto:string;
@Input() clientId: string;
@Input() config:string;
@Output() noServices=new EventEmitter<boolean>();
  stripe: any;
  today = '';
  isLoading = false;
  isBooked = false;
  paymentError = false;
  isError = false;
  paymentCancelled = false;
  isBookedStripe = false;
  submitted = false;
  bookingError = false;
  showForm = false;
  profileId: string;
  daysAvailable: any;
  now = new Date(Date.now() - 864e5);
  serviceNote: string;
  serviceDuration: string;
  serviceFee = false;
  isPaying = false;
  service: string;
  date: any;
  time: any;
  bookedDates: any[];
  serviceDurationDec:number;
  serviceCurrency:string;
  serviceRate:number;
  dbDateConfig: any = {};
formPago:boolean;

  bookingForm = this.formBuilder.group({
    service: ['0'],
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required, Validators.pattern('[0-9-+ ()]*')]],
    address: [''],
    notes: [''],
    appointmentDate: ['', Validators.required],
    time: ['', Validators.required],
    isPaying: [''],
  });

  slots = [];

  profile: any;
  selectedSlot: string;

  datePickerOptions: IAngularMyDpOptions = {};

  ts:any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private ngZone: NgZone,
    private fns: AngularFireFunctions,
    private location: Location,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private profService: ProfileService,
    notifyServ: NotificationService,
    public translate: LoadjsonService,
    private citaServ:CitasService,
    private alertServ:AlertsService
  ) {
    super(notifyServ);

    this.translate.loadCitas().subscribe((resp) => {
      this.ts = resp;
    });
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {

    let activeProfileId;

if(this.profileInput)
 activeProfileId =this.profileInput;
else
 activeProfileId = this.route.snapshot.queryParamMap.get('perfil');

    this.profileId = activeProfileId;
    //console.log(activeProfileId)
    const profile = history.state.profile;
    if (profile) {
      this.profile = profile;

      this.initialize();
    
    } else {
      this.profService.getPublicProfile(activeProfileId).then((res) => {
        if (res) {

            if(!res.services || !res.services.length){
              this.noServices.emit(true);
            }

          this.profile = res;
       
          this.initialize();
        } else {
          //No profile details returned
          this.router.navigate(['/profile/' + activeProfileId]);
        }


      });
    }

  }

  initialize() {
    const status = this.route.snapshot.queryParams.s;
    const userId = this.route.snapshot.queryParams.id;
    //const userName = 'PRUEBA'
    if (this.route.snapshot.queryParams.id) {
    
      if (status == 'pago' || status == 'pendiente') {
        this.loadAppointment(this.route.snapshot.queryParams.id, status);

        /**
          * send notification with status
          * token, title, body, image,link
          */
        const link = 'https://app.januus.com/profile/notification';
        //console.log(link);
        //this.sendNotify(this.profile.ownerId, this.ts.notification, `Status: ${status}`, null, link)

      } else if (status == 'no') {
        this.paymentCancelled = true;
        this.confirmAppointment(this.route.snapshot.queryParams.id, status);
      }
    } else {
      this.setDateSlots(0);
      this.bookingForm.get('service').valueChanges.subscribe((f) => {
        this.setDateSlots(f);
      });
      this.showForm = true;
      this.bookedDates = [];
      if (this.profile.bookedDates) {
        this.bookedDates = this.profile.bookedDates;
      }
      // this.stripe = Stripe(environment.stripe, {
      //   stripeAccount: this.profile.merchantAccountId,
      // });

      // this.stripe = Stripe(
      //   'pk_test_51HdQsaHaq2d0bH3IfLDUOGOGFbfmC2PukHsMQpobnlX6gAx4pNcOipQOgy7YvdLh1oT6Mm7V3qvpvbp7tbZWr8Et00blPUf4P2', {
      //     stripeAccount: this.profile.merchantAccountId
      //   }
      // );
    }
  }

  onDateChanged(event: IMyDateModel) {
    this.date = event.singleDate.epoc;
    this.setTimeSlots(event.singleDate);
  }

  hasValidationErrors(field: string): boolean {
    const control = this.bookingForm.controls[field];
    if (control.invalid && (control.touched || control.dirty)) {
      return true;
    } else if (control.invalid && this.submitted) {
      return true;
    }
    return false;
  }

  get isFormInvalid(): boolean {
    return !this.bookingForm.valid;
  }

  setDateSlots(idx: number) {
    this.daysAvailable = this.profile.services[idx].schedule.availability;
    this.serviceNote = this.profile.services[idx].note;
    this.serviceDuration = this.profile.services[idx].duration;
    this.serviceDurationDec = Number(this.profile.services[idx].duration);
    this.serviceCurrency = this.profile.services[idx].currency;
    this.serviceRate = this.profile.services[idx].rate;
    if (
      this.profile.services[idx].rate > 0 &&
      this.profile.merchantAccountStatus == 'Activo' &&
      !this.profile.services[idx].paymentRequired
    ) {
      this.serviceFee = true;
    }
    this.service = this.profile.services[idx].name;
    let daysToDisable = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
    for (let i = 0; i < this.daysAvailable.length; i++) {
      daysToDisable[this.daysAvailable[i].day] = '';
    }
    // console.log(
    //   this.getUnavailableDates(this.profile.services[idx].unavailableDates)
    // );
    this.datePickerOptions = {
      disableUntil: {
        year: this.now.getFullYear(),
        month: this.now.getMonth() + 1,
        day: this.now.getDate(),
      },
      firstDayOfWeek: 'su',
      disableWeekdays: daysToDisable,
      disableDates: this.getUnavailableDates(
        this.profile.services[idx].unavailableDates
      ),
    };
  }

  setTimeSlots(date: any) {
    let day = date.jsDate.getDay();
    let start,
      end = 0;
    this.slots = [];
    for (let i = 0; i < this.daysAvailable.length; i++) {
      if (this.daysAvailable[i].day == day) {
        start =
          parseInt(this.daysAvailable[i].startTime.split(':')[0]) * 60 +
          parseInt(this.daysAvailable[i].startTime.split(':')[1]);
        end =
          parseInt(this.daysAvailable[i].endTime.split(':')[0]) * 60 +
          parseInt(this.daysAvailable[i].endTime.split(':')[1]);
        let time = this.generateTimeIntervals(
          15,
          start,
          end,
          parseInt(this.serviceDuration),
          date.epoc
        );
        this.slots = this.slots.concat(time);
      }
    }
    this.slots.sort();
  }

  selectSlot(idx: number): void {
    this.selectedSlot = this.slots[idx];
    // console.log("you've selected", this.selectedSlot);
  }

  isSlotSelected(idx: number): boolean {
    return this.slots[idx] === this.selectedSlot;
  }

  bookAppointment() {
    this.submitted = true;
    if (this.bookingForm.valid) {
      this.isLoading = true;
      // console.log(this.bookingForm.value);
      const profileId = this.profileId;
      const {
        name,
        email,
        appointmentDate,
        notes,
        time,
        service,
        phone,
        address,
        isPaying,
      } = this.bookingForm.value;
      let startPay = this.bookingForm.get('isPaying').value;
      // console.log(startPay);
      this.time = this.slots[time];

      let min =
        parseInt(this.time.split(':')[0]) * 60 * 60 +
        parseInt(this.time.split(':')[1]) * 60;
      let date = (appointmentDate.singleDate.epoc + min) * 1000;
      this.date = new Date(date).toLocaleString();
      //const serviceObj = this.profile.services[service];
      const photo=this.userPhoto;
      //console.log('user photo is '+ photo);
      const clientId=this.clientId;

      // ver si tiene costo
      if(this.serviceRate && this.serviceRate > 0){
      //ver el config para saber que hacer
        if(this.profile.config){
          switch (this.profile.config.substring(6, 9)) {

            case '100':
              this.isLoading = false;
            this.formPago=true;

              break;
            case '010':
              this.citaServ.reqCita(this.bookingForm.value, date, profileId, this.service, this.serviceNote,this.serviceCurrency,this.serviceDuration,this.serviceRate,this.profile.fullname, this.profile.phone, this.profile.email,this.userPhoto,this.clientId, this.servicePhoto).then(resp => {
                //habilitar vista con los datos de la reserva
                this.isLoading = false;
                this.isBooked = true;
                //
                this.alertServ.sendMsj('success', this.ts.alertas.peticion);

                //hacer notificacion para peticion de cita
                /**
                * send notification
                * token, title, body, image,link
                */

              }).catch(error => {
                this.isLoading=false;
                this.alertServ.sendMsj('danger', this.ts.alertas.error)
              })
              break;
            case '001':
              this.citaServ.addCita(this.bookingForm.value, date, profileId, this.service, this.serviceNote, this.serviceCurrency, this.serviceDuration, this.serviceRate, this.profile.fullname, this.profile.phone, this.profile.email, this.userPhoto, this.clientId, this.servicePhoto).then(resp => {
                //habilitar vista con los datos de la reserva
                this.isLoading = false;
                this.isBooked = true;
                //
                this.alertServ.sendMsj('success', this.ts.alertas.confirmada);
                /**
                * send notification
                 * token, title, body, image,link
                */
                const link = 'https://app.januus.com/profile/notification';
                //console.log(link);
                //this.sendNotify(this.profile.ownerId, this.ts.notification, null, null, link)

                //enviar emails

              }).catch(error => {
                this.isLoading = false;
                this.alertServ.sendMsj('danger', this.ts.alertas.error)
              })
            break;


          }
        }else{
          this.formPago = true;
        }


      }else{
        this.citaServ.addCita(this.bookingForm.value, date, profileId, this.service, this.serviceNote, this.serviceCurrency, this.serviceDuration, this.serviceRate, this.profile.fullname, this.profile.phone, this.profile.email, this.userPhoto, this.clientId,this.servicePhoto).then(resp => {
          //habilitar vista con los datos de la reserva
          this.isLoading=false;
          this.isBooked = true;

          //
          this.alertServ.sendMsj('success', this.ts.alertas.confirmada);
          /**
          * send notification
           * token, title, body, image,link
          */
          const link = 'https://app.januus.com/profile/notification';
          //console.log(link);
          //this.sendNotify(this.profile.ownerId, this.ts.notification, null, null, link)

          //enviar emails

        }).catch(error => {

          this.alertServ.sendMsj('danger', this.ts.alertas.error)
        })
      }


      //usar cita service para crear la cita


      //una vez que se registro mandar notificacion e emails


      // this.fns
      //   .httpsCallable('functionCall')({
      //     name: 'createAppointment',
      //     payload: {
      //       name,
      //       email,
      //       date,
      //       notes,
      //       service,
      //       phone,
      //       address,
      //       startPay,
      //       profileId,
      //       clientId,
      //       photo,
      //     },
      //   })
      //   .subscribe((val) => {
      //     // console.log('response from firebase cloud function callable');
      //     if (val) {
      //       if (val.id) {
      //         this.ngZone.run(() => {
      //           this.stripe.redirectToCheckout({ sessionId: val.id });
      //         });
      //       } else if (val.init) {
      //         this.ngZone.run(() => {
      //           //console.log(val.init);

      //           window.location.href = val.init;
      //         });
      //       } else if (val.isBooked) {
      //         this.ngZone.run(() => {
      //           //console.log(val);
      //           this.isLoading = false;
      //           this.isBooked = true;
      //           /**
      //            * send notification
      //             * token, title, body, image,link
      //            */
      //           const link = `${window.location.protocol}//www.${window.location.hostname}/solicitudes/${this.profileId}`;
      //           console.log(link);
      //           this.sendNotify(this.profile.ownerId, this.ts.notification, null, null, link)
      //         });
      //       } else if (!val.isBooked && val.paymentFailed) {
      //         this.ngZone.run(() => {
      //           //console.log(val);
      //           this.isError = true;
      //           this.isLoading = false;
      //           //this.isBooked = true;
      //         });
      //       } else {
      //         this.ngZone.run(() => {
      //           this.isError = true;
      //           this.isLoading = false;
      //         });
      //       }
      //     } else {
      //       this.isError = true;
      //       this.isLoading = false;
      //     }
      //   });
    }
  }

  returnToProfile() {
    //this.router.navigate(['perfil', this.profileId]);
    window.history.back();
  }

  private loadAppointment(id, status) {
    //console.log(id);
    this.firestore
      .collection('appointments')
      .doc(id)
      .get()
      .subscribe(
        (val) => {
          //console.log(val);
          if (val.exists) {
            const appt = val.data() as Appointment;
            this.service = appt.service.name;
            this.serviceNote = appt.service.note;
            this.date = new Date(appt.date).toLocaleString();
            this.isBooked = true;
            this.confirmAppointment(id, status);
          } else {
            this.isError = true;
            //console.log("val does not exist?")
          }
          this.isLoading = false;
        },
        (err) => {
          this.isError = true;
          //console.log(err)
          this.isLoading = false;
        }
      );
  }

  private getUnavailableDates(dates: number[]) {
    return dates.map((n) => {
      let jsDate = new Date(n);
      const date = {
        year: jsDate.getFullYear(),
        month: jsDate.getMonth() + 1,
        day: jsDate.getDate(),
      };
      return date;
    });
  }

  private generateTimeIntervals(
    interval: number,
    start: number,
    end: number,
    duration: number,
    date: any
  ) {
    let list = [];
    let bookedTimes = [];
    //Don't show times earlier than 2 hours from now
    let today = new Date();
    let todayString = today.toLocaleDateString();
    let selectedDateString = new Date(date * 1000).toLocaleDateString();
    if (todayString === selectedDateString) {
      let timeLimit = today.getMinutes() + today.getHours() * 60 + 120;
      if (start < timeLimit) {
        start = Math.ceil(timeLimit / 15) * 15;
      }
    }
    if (this.bookedDates) {
      for (let i = 0; i < this.bookedDates.length; i++) {
        let booked = this.bookedDates[i];
        let time = new Date(booked.date);
        let timeString = time.toLocaleDateString();
        //let selectedDateString = new Date(date*1000).toJSON().substring(0,10);
        if (timeString === selectedDateString) {
          const newTime = time.getMinutes() + time.getHours() * 60;
          bookedTimes.push({ time: newTime, duration: booked.duration });
        }
      }
    }
    while (start < end && start + duration <= end) {
      let add = true;
      for (let i = 0; i < bookedTimes.length; i++) {
        //if the appt starts at the time block
        //or if the appt starts during the time block
        //or if the end time of the appointment is during the time block
        //or if the appt takes up the slot and more
        if (
          bookedTimes[i].time == start ||
          (bookedTimes[i].time > start &&
            bookedTimes[i].time < start + interval) ||
          (bookedTimes[i].time + parseInt(bookedTimes[i].duration) > start &&
            bookedTimes[i].time + parseInt(bookedTimes[i].duration) <=
            start + interval) ||
          (bookedTimes[i].time < start &&
            bookedTimes[i].time + parseInt(bookedTimes[i].duration) >
            start + interval)
        ) {
          add = false;
        }
      }
      if (add) {
        list.push(start);
      }
      start += interval;
    }

    return list.map((n) => {
      const date = addMinutes(new Date(2020, 1, 1, 0, 0), n);
      return format(date, 'HH:mm');
    });
  }

  confirmAppointment(id, status) {
    //console.log(status);
    this.fns
      .httpsCallable('functionCall')({
        name: 'confirmAppointment',
        payload: {
          id,
          status,
        },
      })
      .subscribe((val) => {
        // console.log('response from firebase cloud function callable');
        if (val) {
          this.ngZone.run(() => {
            //console.log(val);
            //console.log(val);
          });
        }
      });
  }
}

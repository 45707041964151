import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from '@auth-module/models/auth-state.model';
import { setLoggedClient } from '@client-module/store/client.actions';
import { Mbcliente } from '@models/auth.model';
import { authLogout, set2faPassed, validateCodeSuccess } from './auth.actions';

export const initialState: AuthState = {
  isLogged: false,
  is2faActive: false,
  is2faPassed: false,
  token: null,
  accountIsActive: false,
};

const reducer = createReducer(
  { ...initialState },
  on(
    setLoggedClient,
    (
      state: AuthState,
      { client, accountIsActive }: { client: Mbcliente; accountIsActive?: boolean },
    ) => ({
      ...state,
      isLogged: true,
      is2faActive: !!client?.llaveFactorAutenticacion,
      token: client?.token,
      accountIsActive: accountIsActive ?? state.accountIsActive,
    }),
  ),
  on(set2faPassed, (state: AuthState, { is2faPassed }) => ({ ...state, is2faPassed })),
  on(validateCodeSuccess, (state: AuthState) => ({ ...state, accountIsActive: true })),
  on(authLogout, () => ({ ...initialState })),
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}

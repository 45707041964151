import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { Appointment } from '../models/appointments';
import { ServiceModel } from '../models/service.model';

@Injectable({
  providedIn: 'root'
})
export class CitasService {
  myCitas: BehaviorSubject<Appointment[]> = new BehaviorSubject<Appointment[]>([]);
  myCitas$: Observable<Appointment[]>;


  constructor(private fs: AngularFirestore) {

this.myCitas$=this.myCitas.asObservable();
   }

  loadAppointments(activeProfileId: string) {
    this.fs
      .collection <Appointment>('appointments', (ref) =>
        ref
          .where('profileId', '==', activeProfileId)
          .where('date', '>=', Date.now())
          .where('status', "in", ["Confirmed", "Pending"])
          .orderBy('date', 'asc')
      )
      .valueChanges({idField:'id'}).subscribe(resp => {


        if (resp && resp.length > 0) {


          this.myCitas.next(resp);

        }

      });

   setTimeout(() => {
     this.fs
       .collection<Appointment>('appointments', (ref) =>
         ref
           .where('clientId', '==', activeProfileId)
           .where('date', '>=', Date.now())
           .where('status', "in", ["Confirmed", "Pending"])
           .orderBy('date', 'asc')
       )
       .valueChanges({ idField: 'id' }).subscribe(resp => {


         if (resp && resp.length > 0) {


           this.myCitas.next(resp);

         }

       });
   }, 1800);
  }
  addCita(form: any, date: number, profileId: string, service: string, serviceNote: string, serviceCurrency: string, serviceDuration: string, serviceRate: number, profileName: string, profilePhone: string, profileEmail: string, photo: string,clientId: string, servicePhoto:string) {

    const { name, email, phone, address, notes } = form

    const cita = {
      name,
      email,
      phoneNumber: phone,
      address,
      notes,
      profileId,
      profileName: profileName,
      profilePhone: profilePhone || null,
      profileEmail: profileEmail || null,
      profilePhoto: servicePhoto || null,
      serviceName: service,
      serviceNote: serviceNote || null,
      serviceCurrency: serviceCurrency || null,
      serviceDuration: serviceDuration || null,
      serviceRate: serviceRate,
      photo:photo,
      date,
      isPaid: false, // did they complete payment for the service?
      isView: false,
      status: "Confirmed",
      createdAt: new Date(),
      lastUpdatedAt: new Date(),
      clientId:clientId
    }
    return this.fs.collection('appointments').add(cita)


  }
  reqCita(form: any, date: number, profileId: string, service: string, serviceNote: string, serviceCurrency: string, serviceDuration: string, serviceRate: number, profileName: string, profilePhone: string, profileEmail: string, photo: string, clientId: string, servicePhoto: string) {

    const { name, email, phone, address, notes } = form

    const cita = {
      name,
      email,
      phoneNumber: phone,
      address,
      notes,
      profileId,
      profileName: profileName,
      profilePhone: profilePhone || null,
      profileEmail: profileEmail || null,
      profilePhoto: servicePhoto || null,
      serviceName: service,
      serviceNote: serviceNote || null,
      photo:photo,
      serviceCurrency: serviceCurrency || null,
      serviceDuration: serviceDuration || null,
      serviceRate: serviceRate,
      date,
      isPaid: false, // did they complete payment for the service?
      isView: false,
      status: "Pending",
      createdAt: new Date(),
      lastUpdatedAt: new Date(),
      clientId:clientId
    }
    return this.fs.collection('appointments').add(cita)


  }


  addCita2(form: any, date: number, profileId: string, service: ServiceModel,  clientId: string, userPhoto:string) {

    const { name, email, phone, address, notes } = form

    const cita = {
      name,
      email,
      phoneNumber: phone,
      address,
      notes,
      profileId,
      profileName: service.profileName,
      profilePhone: service.profilePhone || null,
      profileEmail: service.profileEmail || null,
      profilePhoto: service.profilePhoto || null,
      serviceName: service.serviceName,
      serviceNote: service.description || null,
      serviceCurrency: service.serviceCurrency || null,
      serviceDuration: service.duration || null,
      serviceRate: service.serviceRate,
      photo: userPhoto || null,
      date,
      isPaid: false, // did they complete payment for the service?
      isView: false,
      status: "Confirmed",
      createdAt: new Date(),
      lastUpdatedAt: new Date(),
      clientId: clientId
    }
    return this.fs.collection('appointments').add(cita)


  }
  reqCita2(form: any, date: number, profileId: string, service: string, serviceNote: string, serviceCurrency: string, serviceDuration: string, serviceRate: number, profileName: string, profilePhone: string, profileEmail: string, photo: string, clientId: string, servicePhoto: string) {

    const { name, email, phone, address, notes } = form

    const cita = {
      name,
      email,
      phoneNumber: phone,
      address,
      notes,
      profileId,
      profileName: profileName,
      profilePhone: profilePhone || null,
      profileEmail: profileEmail || null,
      profilePhoto: servicePhoto || null,
      serviceName: service,
      serviceNote: serviceNote || null,
      photo: photo,
      serviceCurrency: serviceCurrency || null,
      serviceDuration: serviceDuration || null,
      serviceRate: serviceRate,
      date,
      isPaid: false, // did they complete payment for the service?
      isView: false,
      status: "Pending",
      createdAt: new Date(),
      lastUpdatedAt: new Date(),
      clientId: clientId
    }
    return this.fs.collection('appointments').add(cita)


  }

}

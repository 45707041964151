import { ClientState } from '../models/client-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
  setActiveProfile,
  setClient2faKey,
  setClientSubscription,
  setContactRequest,
  setContactsRequest,
  setLoggedClient,
  setProfileList,
  setProfileReservation,
  setProfileReservations,
  setSelectedPlan,
  setShowInitialSteps,
  setTokenFirebaseClientSuccess,
} from './client.actions';
import { authLogout } from '../../auth/store/auth.actions';
import {
  checkCodeForVerifyAccountSuccess,
  removeClientToValidate,
  searchClientAccountsError,
  searchClientAccountsIsLoading,
  searchClientAccountsLoad,
  searchClientAccountsSuccess,
  setClientToValidate,
  setClientToValidateIsLogged,
  setShowModalForCreateAccount,
  updateClientSuccess,
  updateIndividualClientSuccess,
  updateLegalRepresentative,
} from '../../account/store/account.actions';
import { chargeLegalRepresentativeSuccess } from '@account-module/store/account.actions';

export const initialState: ClientState = {
  client: null,
  showInitialSteps: false,
  activeProfile: null,
  profileList: [],
  activeProfileContactRequest: [],
  activeProfileReservations: [],
  isLoadingClients: false,
  clientsResults: [],
  selectedClientToValidate: null,
  showModalForPassword: false,
  selectedClientToValidateIsLogged: false,
  showModalForCreateAccount: false,
};
const reducer = createReducer(
  { ...initialState },
  on(
    setLoggedClient,
    (state: ClientState, { client }): ClientState => ({
      ...state,
      client,
    }),
  ),
  on(
    updateClientSuccess,
    (state: ClientState, { client }): ClientState => ({
      ...state,
      client: { ...state.client, ...client },
    }),
  ),
  on(
    chargeLegalRepresentativeSuccess,
    (state: ClientState, { legalRepresentative }): ClientState => ({
      ...state,
      selectedClientToValidate: legalRepresentative,
      selectedClientToValidateIsLogged: true,
    }),
  ),
  on(
    updateLegalRepresentative,
    (state: ClientState, { legalRepresentative }): ClientState => ({
      ...state,
      client: {
        ...state.client,
        mbRepresentante: legalRepresentative,
      },
    }),
  ),
  on(
    setShowInitialSteps,
    (state: ClientState, { showInitialSteps }): ClientState => ({
      ...state,
      showInitialSteps,
    }),
  ),
  on(
    setActiveProfile,
    (state: ClientState, { activeProfile }): ClientState => ({
      ...state,
      activeProfile,
    }),
  ),
  on(
    setProfileList,
    (state: ClientState, { profileList }): ClientState => ({
      ...state,
      profileList,
    }),
  ),
  on(
    setContactsRequest,
    (state: ClientState, { activeProfileContactRequest }): ClientState => ({
      ...state,
      activeProfileContactRequest,
    }),
  ),
  on(
    setContactRequest,
    (state: ClientState, { activeProfileContactRequest }): ClientState => ({
      ...state,
      activeProfileContactRequest: [
        ...state.activeProfileContactRequest,
        activeProfileContactRequest,
      ],
    }),
  ),
  on(
    setProfileReservations,
    (state: ClientState, { activeProfileReservations }): ClientState => ({
      ...state,
      activeProfileReservations,
    }),
  ),
  on(
    setProfileReservation,
    (state: ClientState, { activeProfileReservation }): ClientState => ({
      ...state,
      activeProfileReservations: [...state.activeProfileReservations, activeProfileReservation],
    }),
  ),
  on(
    setClient2faKey,
    (state: ClientState, { llaveFactorAutenticacion }): ClientState => ({
      ...state,
      client: {
        ...state.client,
        llaveFactorAutenticacion,
      },
    }),
  ),
  on(
    setClientSubscription,
    (state: ClientState, { mbCliente }): ClientState => ({
      ...state,
      client: {
        ...state.client,
        ...mbCliente,
      },
    }),
  ),
  on(
    setTokenFirebaseClientSuccess,
    (state: ClientState, { tokenFirebase }): ClientState => ({
      ...state,
      client: {
        ...state.client,
        listaTokenFirebase: [...state.client.listaTokenFirebase, tokenFirebase]      },
    }),
  ),
  on(
    setSelectedPlan,
    (state: ClientState, { selectedPlan }): ClientState => ({
      ...state,
      client: {
        ...state.client,
        planSeleccionado: selectedPlan,
      },
    }),
  ),
  on(
    searchClientAccountsIsLoading,
    (state: ClientState): ClientState => ({
      ...state,
      isLoadingClients: true,
    }),
  ),
  on(
    searchClientAccountsLoad,
    (state: ClientState): ClientState => ({
      ...state,
      isLoadingClients: true,
    }),
  ),
  on(
    searchClientAccountsSuccess,
    (state: ClientState, { clientsResults }): ClientState => ({
      ...state,
      clientsResults,
      isLoadingClients: false,
    }),
  ),
  on(
    searchClientAccountsError,
    (state: ClientState): ClientState => ({
      ...state,
      clientsResults: [],
      isLoadingClients: false,
    }),
  ),
  on(
    setClientToValidate,
    (state: ClientState, { selectedClientToValidate }): ClientState => ({
      ...state,
      selectedClientToValidate,
      showModalForPassword: true,
    }),
  ),
  on(
    removeClientToValidate,
    (state: ClientState): ClientState => ({
      ...state,
      selectedClientToValidate: null,
      selectedClientToValidateIsLogged: false,
      showModalForPassword: false,
    }),
  ),
  on(
    setClientToValidateIsLogged,
    (state: ClientState): ClientState => ({
      ...state,
      selectedClientToValidateIsLogged: true,
      showModalForPassword: false,
      clientsResults: [],
    }),
  ),
  on(
    updateIndividualClientSuccess,
    (state: ClientState, { client }): ClientState => ({
      ...state,
      selectedClientToValidate: {
        ...state.selectedClientToValidate,
        ...client,
      },
      selectedClientToValidateIsLogged: true,
      showModalForCreateAccount: false,
    }),
  ),
  on(
    checkCodeForVerifyAccountSuccess,
    (state: ClientState): ClientState => ({
      ...state,
      selectedClientToValidate: {
        ...state.selectedClientToValidate,
        codigo: null,
      },
    }),
  ),
  on(
    setShowModalForCreateAccount,
    (state: ClientState, { showModalForCreateAccount }): ClientState => ({
      ...state,
      showModalForCreateAccount,
    }),
  ),
  on(authLogout, (state: ClientState): ClientState => ({ ...initialState })),
);

export function clientReducer(state: ClientState | undefined, action: Action) {
  return reducer(state, action);
}

export enum ProfileViewMode {
  OWNER_VIEW,
  PUBLIC_VIEW,
}

// https://en.m.wikipedia.org/wiki/ISO_4217
export enum Currency {
  USD = 'USD',
  COP = 'COP',
  MXN = 'MXN',
}

export enum PriceRate {
  HOUR = 'hh',
  DAY = 'dd',
  WEEK = 'ww',
  MONTH = 'MM',
  YEAR = 'YY',
  SERVICE = 'service',
}

export enum HeightUnit {
  CM = 'cm',
  FT_IN = 'ft_in',
}

export enum WeightUnit {
  KILOGRAMS = 'kgs',
  POUNDS = 'lbs',
}

export interface Link {
  type: LinkType;
  url: string;
}

export enum LinkType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  TIKTOK = 'tiktok',
  TIDAL = 'tidal',
  YOUTUBE = 'youtube',
  SPOTIFY = 'spotify',
  WHATSAPP = 'wa.me',
  IMDB = 'imdb',
  PINTEREST = 'pinterest',
  EMAIL = 'email',
  TEL = 'tel',
  DEEZER = 'deezer',
  APPLE = 'apple',
  // AMAZON = 'amazon',
  APPLE_MUSIC = 'music.apple',
  AMAZON_MUSIC = 'amazon',
  OTHER = 'other',
}
export const linkTypeMap = {
  facebook: LinkType.FACEBOOK,
  linkedin: LinkType.LINKEDIN,
  instagram: LinkType.INSTAGRAM,
  twitter: LinkType.TWITTER,
  tiktok: LinkType.TIKTOK,
  tidal: LinkType.TIDAL,
  spotify: LinkType.SPOTIFY,
  youtube: LinkType.YOUTUBE,
  imdb: LinkType.IMDB,
  pinterest: LinkType.PINTEREST,
  deezer: LinkType.DEEZER,
  'apple-music': LinkType.APPLE_MUSIC,
  'music.apple': LinkType.APPLE_MUSIC,
  amazon: LinkType.AMAZON_MUSIC,
};
export enum MerchantAccountStatus {
  INACTIVE = 'Sin Activar',
  ACTIVE = 'Activo',
  PENDING = 'En Proceso',
}

export enum MerchantPlatform {
  STRIPE = 'Stripe',
  MP = 'Mercado Pago',
}
